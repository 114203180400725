@import "../../mixins";

.footer {
  background-color: #132845;

  &__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    padding: 50px 0 34px;
  }

  &__socialmedia {
    display: flex;
    gap: 26px;
  }

  &__links {
    display: flex;
    gap: 19px;
    @include media-breakpoint-up(md){
    gap: 32px;
  }
    flex-wrap: wrap;

    a {
      color: var(--white);
      text-transform: capitalize;
      text-decoration: none;
      font-family: var(--font-family-medium);
      font-size: var(--font-size-14);
      @include media-breakpoint-up(md){
      font-size: var(--font-size-base);
      }
      line-height: 24px;
      cursor: pointer;
      &:hover{
        color: var(--white);
        text-decoration: underline;
      }
    }
  }

  &__madeby {
    color: var(--white);
    text-decoration: none;
    font-family: var(--font-family-medium);
    font-size: var(--font-size-14);
    @include media-breakpoint-up(md){
    font-size: var(--font-size-base);
    }
    line-height: 24px;

    span {
      padding: 0 12px;
      &:first-child{
       
        padding: 0 4px;
        padding-left: 0;
        +svg{
          margin-right: 16px;
        }
       
        @include media-breakpoint-up(md){
       
        padding-left: 12px;
        }
     
      }
    }
    margin-bottom: 24px;
  }

  .copyright {
    padding: 24px 0 24px;
    border-top: 1px solid #404f65;
    @include media-breakpoint-down(md){
      flex-flow: column-reverse;
    }
    span {
      color: var(--gray-400);
    }

    .footer__links {
      display: flex;
      gap: 32px;

      a {
        color: var(--gray-400);
      }
    }
  }
  .p-0 {
    padding: 0;
  }
}
