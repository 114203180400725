




/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Poppins Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Regular'), url('../public/fonts/Poppins-Regular.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Italic'), url('../public/fonts/Poppins-Italic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Thin'), url('../public/fonts/Poppins-Thin.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Thin Italic'), url('../public/fonts/Poppins-ThinItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraLight'), url('../public/fonts/Poppins-ExtraLight.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraLight Italic'), url('../public/fonts/Poppins-ExtraLightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Light';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Light'), url('../public/fonts/Poppins-Light.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Light Italic'), url('../public/fonts/Poppins-LightItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Medium'), url('../public/fonts/Poppins-Medium.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Medium Italic'), url('../public/fonts/Poppins-MediumItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins SemiBold'), url('../public/fonts/Poppins-SemiBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins SemiBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins SemiBold Italic'), url('../public/fonts/Poppins-SemiBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Bold'), url('../public/fonts/Poppins-Bold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Bold Italic'), url('../public/fonts/Poppins-BoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraBold'), url('../public/fonts/Poppins-ExtraBold.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins ExtraBold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins ExtraBold Italic'), url('../public/fonts/Poppins-ExtraBoldItalic.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Black';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Black'), url('../public/fonts/Poppins-Black.woff') format('woff');
  }
  

  @font-face {
  font-family: 'Poppins Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Poppins Black Italic'), url('../public/fonts/Poppins-BlackItalic.woff') format('woff');
  }