@import "../../../../mixins";
// @import "../../../../global";

.no-history {
  text-align: center;
  background-color: var(--white);
  &__title {
    margin-bottom: 0;
    margin-top: 40px;
    font-size: var(--font-size-base);
    @include media-breakpoint-up(md) {
      font-size: var(--font-size-36);
    }
  }
  &__text {
    margin-top: 5px;
    font-size: var(--font-size-14);
    color: var(--gray-800);
    padding: 0 16px;
    font-family: var(--font-fmily--medium);
    @include media-breakpoint-up(sm) {
      font-size: var(--font-size-20);
    }
  }
  &__img{
    @include media-breakpoint-down(md) {
    max-width: 180px;
    margin: 0 auto;
    }
  }
  .align-center {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
  }
}
