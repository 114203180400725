@import "../../../mixins";
// @import "../../../global";

.vehicle {
  margin-top: 20px;

  &__cards {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    width: 100%;
    overflow-x: auto;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }
  }

  &__card {
    cursor: pointer;
    flex: 0 1 100%;
    min-width: 266px;
 
    @include media-breakpoint-up(md) {
      flex: 0 1 48%;
      max-width: 266px;
    }

    @include media-breakpoint-up(lg) {
      flex: 0 1 25%;
    }

    background-color: var(--white);
    border: 2px solid var(--gray-1);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    display: flex;

    .MuiCheckbox-root {
      position: absolute;
      top: 0;
      right: 0;
    }

    &:hover {
      border: 2px solid var(--blue-2);
    }

    .card-image {
      height: 96px;
      max-width: 102px;
      background-color: var(--white);
      display: flex;
      align-items: center;
    }

    .card-content {
      padding: 16px 16px;
      padding-top: 26px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &.active {
      border-bottom: 6px solid var(--blue-2);
    }

    .MuiSvgIcon-root {
      fill: var(--blue-2);
    }
  }
}

.vehicle-history {
  background-color: var(--white);
  border: 1px solid #e2e9f5;
  border-radius: 6px;
  @include media-breakpoint-up(md) {
  margin-top: 16px;
  }

  .service-details {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
    }

    &__left {
      // padding: 24px 16px;
      border: 0;
      padding: 24px 16px 0;
      margin-bottom: 0;
      @include media-breakpoint-up(md) {
      padding: 24px 16px;
      margin-bottom: 26px;
      }
      
      border-right: 1px solid #e2e9f5;
      flex: 0 1 100%;

      @include media-breakpoint-up(lg) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        flex: 0 1 76%;
        margin: 0;
      }
    }

    &__right {
      flex: 0 1 100%;
      border-left: 0;
      border: 0;
      @include media-breakpoint-up(md) {
        flex: 0 1 24%;
        border: 0;
        margin: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      text-align: center;
      padding: 32px 24px 24px;
    }

    &__block {
      display: flex;
     
      flex-wrap: wrap;

      @include media-breakpoint-up(md) {
      flex-wrap: wrap;
        gap: 40px;
      }

      @include media-breakpoint-up(lg) {
        gap: 1px;
      }
      @include media-breakpoint-up(xl) {
        gap: 10px;
      }

      .display-item {
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
          flex-wrap: nowrap;
          gap: 25px;
         
        }

        &__group {
          // width: 85px;
          display: flex;
          flex-direction: column;

          text-align: left;
          @include media-breakpoint-down(md) {
            width: 100%
          }
          // gap: 36px;
        }

        .progress-bar-label {
          @extend .sm-text-light;
          text-align: left;
        }
      }
    }
    &__item {
      border: 0;
    }
    &__progress {
      .progress-bar {
        width: 100% !important;
        margin-bottom: 22px;
      }
    }
    &__item-img {
      @include media-breakpoint-down(md) {
        width: 100%;
        text-align: center;
      }
    }
  }
  .service-details__item {
    // border-right: 1px solid #d6dfeb;
    &.display-item{
      @include media-breakpoint-up(md) {
        flex: 0 1 58%;
        }
      @include media-breakpoint-up(lg) {
        flex: 0 1 64%;
        }
      .service-details__item-img{
        max-width: 200px;
        
        @include media-breakpoint-up(md) {
          max-width: 36%;
        }
        @include media-breakpoint-up(xl) {
          max-width: 230px;
        }
      }
   
  }

}
  .service-details__progress .progress-block {
    width: 100% !important;
    @include media-breakpoint-up(md) {
      width: 75px !important;
      }
      @include media-breakpoint-up(lg) {
        width: 85px !important;
        }
    @include media-breakpoint-up(xl) {
    width: 104px !important;
    }
}
// .history-table{
//   display: none;
// }
}
