@import "../../mixins";

.career {
    background-color: #F8F9FA;

    .container {
        max-width: 1140px;
    }

    &__badge {
        border-radius: 36px;
        color: var(--blue-1);
        background-color: #EBF3FE;
        font-size: 12px;
        font-family: var(--font-family--medium);
        padding: 2px 8px;
        display: inline-block;
        margin-bottom: 16px;
        margin-top: 50px;

        @include media-breakpoint-up(sm) {
            margin-top: 96px;
        }

    }

    &__title {
        font-size: 24px;
        line-height: 36px;

        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 44px;
        }

        color: #2A3342;

        font-family: var(--font-family--bold);
        letter-spacing: -2%;
        margin-bottom: 16px;
    }

    &__text {
        font-size: 16px;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 30px;
            color: #556987;

        }
    }

    &__item {
        text-align: center;

        @include media-breakpoint-up(sm) {
            display: flex;
            height: 110px;
            text-align: left;
        }

        justify-content: space-between;
        align-items: center;

        padding: 24px;
        margin-bottom: 16px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 0px 4px 14px -12px #333F51;
    }

    &__item-inner {
        @include media-breakpoint-up(sm) {
            display: flex;
        }

        gap:34px;
        align-items: center;
    }

    &__item-title {
        color: #333F51;
        margin-bottom: 5px;
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            font-size: 20px;
            margin-bottom: 0;
        }

        line-height: 30px;
        font-family: var(--font-family--semi-bold);
    }

    &__item-wrkmode {
        font-size: 14px;
        margin-bottom: 5px;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
            margin-bottom: 0;
        }

        line-height: 24px;
        font-family: var(--font-family--medium);
    }

    &__item-wrktype {
        font-size: 14px;
        margin-bottom: 16px;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
            margin-bottom: 0;
        }

        line-height: 24px;
        font-family: var(--font-family--medium);
    }

    &__item-action {
        padding: 4px 24px;

        @include media-breakpoint-up(sm) {
            padding: 10px 24px;
        }

        border: 1px solid var(--blue-1);

        &:hover {
            border: 1px solid var(--blue-1);
        }
    }

    .form__group {
        margin-bottom: 0;
    }

    .getintouch {
        margin-top: 235px;
    }

    &__block {
        margin-top: 36px;
    }

    .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
        background-color: #fff;
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include media-breakpoint-up(sm) {
         flex-direction: row;
        gap: 20px;
        align-items: center;
        }
       
        font-size: 16px;
        line-height: 20px;
        font-family: var(--font-family--medium);
        color: #556987;
        padding-bottom: 30px;

    }

    &--bg {
        background-color: #fff;
        padding-bottom: 75px;
        @include media-breakpoint-up(sm) {
          padding-bottom: 150px;
        }
        .button{
            margin-top: 30px;
            @include media-breakpoint-up(sm) {
                margin-top: 50px;
            }
            border: 1px solid var(--blue-1);

            &:hover {
                border: 1px solid var(--blue-1);
            }
        }
    }

    &__info-title {
        line-height: 30px;
        font-size: 20px;
        color: #333F51;
        font-family: var(--font-family--semi-bold);
        padding: 25px 0 12px;
        @include media-breakpoint-up(sm) {
        padding: 45px 0 24px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 32px;
        }
    }

    &__info-list {
        color: #556987;
        line-height: 30px;
        font-size: 16px;
        font-family: var(--font-family--medium);

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }
    }
}