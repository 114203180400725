.snackbardialog {
  svg {
    width: auto !important;
  }
  .MuiPaper-root {
    background: var(--dark-blue);
  }
  .MuiAlert-icon {
    align-items: center;
  }
  .MuiAlert-message {
    color: var(--white);
    font-size: var(--font-base);
    font-weight: 400;
  }
}
