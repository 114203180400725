.notFoundPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  align-items: center;
  h1 {
    font-size: 60px;
    color: var(--gray-900);
  }
  p {
    font-size: 20px;
    color: var(--gray-500);
  }
  .action {
    margin-top: 30px;
    display: flex;
    gap: 30px;
  }
}
