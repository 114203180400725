@import "../../mixins";

.header {
  background-color: var(--dark-blue);

  &__block {
    display: flex;
    justify-content: space-between;
    min-height: 80px;
    align-items: center;

   
  }

  &__right {
    display: flex;
  }

  &__login {
    color: red !important;

    &--link {
      color: red;
    }
  }
  &.add-border{
    .header__block{
      @include media-breakpoint-up(md) {
        border-bottom: 1px solid var(--white);
      }
    }
  }
}

.menu {
  display: none;

  &.active {
    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      position: absolute;
      width: 100%;
      background: #132845;
      left: 0;
      top: 80px;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      padding: 16px;
      z-index: 999;

      .menu__link {
        color: var(--white);
        text-decoration: none;

        &:hover {
          color: var(--white);
          border-bottom: 2px solid var(--white);
        }
      }
    }
  }
  .menu__item {
    padding: 8px 0;
    z-index: 1000;
    .profile {
      position: relative;
      &__drop {
        height: 325px;
        width: 260px;
        background-color: white;
        position: absolute;
        border-radius: 10px;
        top: 10px;
        right: 0;
        left: 0;
        @include media-breakpoint-up(md) {
          left: auto;
        }
        box-shadow: 0px 32px 64px rgba(85, 105, 135, 0.4);
        padding: 24px;
        .logout {
          margin-top: 50px;
          cursor: pointer;
          &:hover {
            color: var(--blue-1);
          }
          &:active {
            color: var(--blue-1);
          }
        }
        .user-navigation {
          .menu-item-drop {
            display: flex;
            gap: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            z-index: 1000;
            &:hover {
              color: var(--blue-1);
            }
            &:active {
              color: var(--blue-1);
            }
          }
        }
        .user-details {
          display: flex;
          gap: 20px;
          align-items: center;
          .user-picture {
            background-color: var(--gray-200);
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .user-name {
            font-size: var(--font-size-20);
            font-weight: var(--font-family--medium);
            color: var(--gray-900);
            .number {
              font-size: var(--font-size-12);
              font-weight: var(--font-family--Light);
              color: var(--gray-500);
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    display: flex;
    gap: 24px;

    @include media-breakpoint-up(lg) {
      gap: 48px;
    }

    font-size: var(--font-size-base);

    &__item {
    }

    &__link {
      color: var(--white);
      text-decoration: none;

      &:hover {
        color: var(--white);
        border-bottom: 2px solid var(--white);
      }
    }
  }
}

.menu-button {
 
  background: transparent;
  border: 0;
  svg{
    width: 24px;
    height: 21px;
    background: transparent;
    path{
      fill: #fff;
    }
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

.login-dialog {
  text-align: center;
  .MuiDialog-paperWidthSm {
    width: 450px;
    padding:24px;
    @include media-breakpoint-up(md) {
    padding: 30px;
    }
  }
  .MuiDialogTitle-root {
    .MuiTypography-root {
      text-align: center;
      color: var(--gray-900, #2a3342);
      font-family: var(--font-family--bold);
      font-size: 30px;
      line-height: 38px;
    }
  }
  .MuiDialogActions-root {
    .MuiBox-root {
      width: 100%;
      // padding: 0 15px;
      margin-bottom: 0;
    }
    button {
      background-color: var(--blue-1);
      height: 46px;
      display: flex;
      align-items: center;
      font-size: var(--font-size-base);
      font-family: var(--font-family--medium);
      line-height: 24px;
      color: var(--gray-050);
    }
  }
}

.MuiDialogContent-root{
  
    padding: 8px 0px!important;
  
}
.MuiDialogActions-root{
  padding: 8px 0px!important;
}
.MuiDialogTitle-root {
  padding: 0 0 16px!important;
}
.logo-desktop{
  
  display: none;
  @include media-breakpoint-up(md){
    display: block;
  }
}
.logo-mobile{
  @include media-breakpoint-up(md){
    display: none;
  }
}
.mobile-dropdown{
  position: relative;
  >.menu__item{
    margin-right: 24px;
a{
  font-size: 16px;
  color: #fff;
  text-decoration: none;
}
    
  }
  @include media-breakpoint-up(md){
    display: none;
  }
  .profile {
 
    &__drop {
      height: 325px;
      width: 260px;
      background-color: white;
      position: absolute;
      border-radius: 10px;
      top: 55px;
      right: -30px;
      left: auto;
      z-index: 1000;
      @include media-breakpoint-up(md) {
        left: auto;
      }
      box-shadow: 0px 32px 64px rgba(85, 105, 135, 0.4);
      padding: 24px;
      .logout {
        margin-top: 50px;
        cursor: pointer;
        &:hover {
          color: var(--blue-1);
        }
        &:active {
          color: var(--blue-1);
        }
      }
      .user-navigation {
        .menu-item-drop {
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
          cursor: pointer;
          &:hover {
            color: var(--blue-1);
          }
          &:active {
            color: var(--blue-1);
          }
        }
      }
      .user-details {
        display: flex;
        gap: 20px;
        align-items: center;
        .user-picture {
          background-color: var(--gray-200);
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .user-name {
          font-size: var(--font-size-20);
          font-weight: var(--font-family--medium);
          color: var(--gray-900);
          .number {
            font-size: var(--font-size-12);
            font-weight: var(--font-family--Light);
            color: var(--gray-500);
          }
        }
      }
    }
  }
}