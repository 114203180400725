@import "../../mixins";

.sideBar {
  &__container {
    background-color: var(--white);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 24px;
    box-shadow: 0px 6px 7px 6px rgba(85, 105, 135, 0.08);
    @include media-breakpoint-up(md) {
    padding: 25px;
    }

    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }

    .MuiOutlinedInput-root {
      height: 46px;
      border-radius: 8px;
      color: var(--gray-900);
      font-size: var(--font-size-16);
      font-family: var(--font-family--bold);
      overflow: hidden;
    }
    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  &__subHeader {
    color: var(--gray-800);
    font-size: var(--font-size-14);
    font-family: var(--font-family--medium);
  }

  &__brand {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__name {
      color: var(--gray-900);
      font-size: var(--font-size-base);
      @include media-breakpoint-up(md) {
      font-size: var(--font-size-20);
      }
      font-family: var(--font-family--bold);
    }

    &__button {
      color: var(--blue-4);
      font-size: var(--font-size-14);
      font-family: var(--font-family--medium);
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  &__brandImage {
    width: 100%;
    height: 180px;
    // background-color: var(--gray-2);
    margin: 5px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &__img {
      height: inherit;
      max-width: 180px;
    }
  }
  .pt-border {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid var(--gray-200);
  }
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--gray-200);
    margin-bottom: 10px;
    &__type {
      color: var(--gray-900);
      font-size: var(--font-size-base);
      @include media-breakpoint-up(md) {
      font-size: var(--font-size-18);
      }
      font-family: var(--font-family--medium);
      margin-right: 5px;
    }
    &__price {
      color: var(--gray-900);
      font-size: var(--font-size-base);
      @include media-breakpoint-up(md) {
      font-size: var(--font-size-18);
      }
      font-family: var(--font-family--bold);
    }
  }
  &__input {
    margin-top: 10px;
    .MuiSvgIcon-root {
      color: red;
      cursor: pointer!important;
    }
  }
  &__costDetails {
    margin-top: 10px;
    .costIndividual {
      display: flex;
      justify-content: space-between;
    }
    .costHeader {
      color: var(--gray-800);
      font-size: var(--font-size-14);
      font-family: var(--font-family--medium);
    }
    .cost {
      color: var(--gray-900);
      font-size: var(--font-size-14);
      font-family: var(--font-family--bold);
    }
    .discount {
      color: var(--info-600);
    }
    .total {
      color: var(--gray-900);
      font-size: var(--font-size-14);
      font-family: var(--font-family--bold);
    }
  }
  &__payment {
    margin-top: 10px;
    .MuiButton-root {
      background-color: var(--blue-1);
      font-size: var(--font-size-16);
      font-family: var(--font-family--semi-bold);
      text-transform: capitalize;
      cursor: pointer;
      margin-bottom: 10px;
    }
  }
  &__footer {
    margin-top: 20px;
    p {
      color: var(--gray-500);
      font-size: var(--font-size-14);
      font-family: var(--font-family--semi-bold);
      margin: 0;
    }
    .note {
      color: var(--gray-500);
      font-size: var(--font-size-12);
      font-family: var(--font-family--light);
    }
  }
  .form__input-error {
    color: red;
    font-size: 12px;
    p {
      padding: 0;
      margin: 0;
    }
  }
  .form__input-info {
    color: var(--green-1);
    font-size: 14px;
    p {
      padding: 0;
      margin: 0;
    }
  }
  .MuiButtonBase-root.MuiButton-root.MuiButton-outlined {
    font-family: var(--font-family--bold);
  }
}

.MuiDialog-container .MuiDialogTitle-root+.css-ypiqx9-MuiDialogContent-root {
  padding-top: 10px;
}
.MuiDialog-container .css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
  font-family: var(--font-family--bold);
}