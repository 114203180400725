@import "../../../mixins";

.faq {
  padding: 64px 0;
  // background-color: #F7F8F9;

  &__title {
    color: var(--gray-900);
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    @include media-breakpoint-up(md) {
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    }
    letter-spacing: -2%;
    font-family: var(--font-family--bold);
    margin-bottom: 16px;
 
    @include media-breakpoint-up(lg) {
      font-size: var(--font-size-h1);
      line-height: 60px;
    }
  }
  &__text {
    margin-bottom: 64px;
   
    font-size: var(--font-size-base);
    line-height: 22px;
    text-align: center;
    @include media-breakpoint-up(md) {
    font-size: var(--font-size-20);
    line-height: 30px;
    text-align: left;
    }
    color: var(--gray-500);
   
    font-family: var(--font-family--medium);
  }
  &__item {
    margin-bottom: 42px;
    text-align: center;
    @include media-breakpoint-up(sm) {
      text-align: left;
      flex: 0 1 31%;
    }
    &.faq-align {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__block {
    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
      gap: 16px;
    }
  }
  &__item-title {
    margin: 10px 0;
    font-family: var(--font-family--bold);
    font-size: var(--font-size-18);
    line-height: 25px;
    @include media-breakpoint-up(xl) {
      padding-right: 140px;
    }
    @include media-breakpoint-up(md) {
    margin: 24px 0;
    font-size: var(--font-size-20);
    line-height: 30px;
    }
  }
  &__item-text {
    @include media-breakpoint-up(xl) {
      padding-right: 96px;
    }
    font-size: var(--font-size-base);
    color: var(--gray-500);
    line-height: 24px;
    font-family: var(--font-family--medium);
  }
}
