@import "../../mixins";

.faqpage {
    background-color: #f7f8f9;

    .container {
        max-width: 1140px;
    }
 &__badge{
    
        border-radius: 36px;
        color: var(--blue-1);
        background-color: #EBF3FE;
        font-size: 12px;
        font-family: var(--font-family--medium);
        padding: 2px 8px;
        display: inline-block;
        margin-bottom: 16px;
        margin-top: 50px;
        @include media-breakpoint-up(sm) {
        margin-top: 96px;
        }
      
 }
    &__title {
        font-size: 24px;
        line-height: 36px;

        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 60px;
        }

        color: #2A3342;

        font-family: var(--font-family--bold);
        letter-spacing: -2%;
        margin-bottom: 16px;
    }

    &__text {
        font-size: 16px;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 30px;
            color: #556987;

        }
    }

    &__left {
        max-width: 750px;
    }

    &__block {
        display: flex;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
    }
&__right{
    margin-bottom: 101px;
}
    .accordion {
        border: 0;
        border-radius: 0px;
        margin: 64px 0 88px;

        &__item {}

        &__button {
            position: relative;
            padding: 40px 75px 40px 40px;

            background-color: var(--white);
            font-size: var(--font-size-base);

            @include media-breakpoint-up(md) {
                font-size: var(--font-size-20);
            }

            font-family: var(--font-family--bold);
            color: var(--gray-900);
            line-height: 30px;

            &::before {
                position: absolute;

                right: 25px;
                background-image: url("../../../public/images/circled-down-arrow.svg");
                height: 24px;
                width: 24px;
                margin-right: 12px;
                border-bottom: 0;
                border-right: 0;
                transform: none;

            }

        }

        &__panel {
            padding: 0 40px 40px;
            animation: fadein 0.35s ease-in;
            background: white;
            box-shadow: 0px 1px rgba(85, 105, 135, 0.1);
            border-bottom-left-radius: 2px;
            border-bottom-right-radius: 2px;

            p {
                margin-bottom: 0;
                font-size: var(--font-size-14);

                @include media-breakpoint-up(md) {
                    font-size: var(--font-size-base);
                }

                font-family: var(--font-family--medium);
                color: var(--gray-900);
                line-height: 30px;
                color: var(--gray-500);
            }
        }
    }

    .accordion__item+.accordion__item {
        border-top: 0;
        margin-top: 32px;
    }

    .accordion__button[aria-expanded='true'] {
        padding-bottom: 16px;

        &::before {
            transform: rotate(180deg);
        }

    }

    .accordion__button[aria-selected='true'] {
        padding-bottom: 16px;

        &::before {
            transform: rotate(180deg);
        }



    }
}