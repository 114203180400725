@import "../../mixins";


.service {

    background-color: #F8F9FA;

    .container {
        max-width: 1140px;
    }

    &__badge {
        border-radius: 36px;
        color: var(--blue-1);
        background-color: #EBF3FE;
        font-size: 12px;
        font-family: var(--font-family--medium);
        padding: 2px 8px;
        display: inline-block;
        margin-bottom: 16px;
        margin-top: 26px;

        @include media-breakpoint-up(md) {
            margin-top: 96px;
        }

    }

    &__title {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 16px;
        @include media-breakpoint-up(md) {
            font-size: 48px;
            line-height: 44px;
            margin-bottom: 24px;
        }

        color: #2A3342;

        font-family: var(--font-family--bold);
        letter-spacing: -2%;
       
    }

    &__text {
        font-size: 16px;
        line-height: 24px;

        @include media-breakpoint-up(md) {
            font-size: 20px;
            line-height: 30px;
            color: #556987;

        }
    }

    &__block {
        display: flex;
        flex-wrap: wrap;

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            flex: 0 1 75%;
            padding-right: 32px;
        }
    }

    &__block-text {
        p{
            color: #556987;
            font-family: var(--font-family--medium);
            font-size: var(--font-size-14);
            @include media-breakpoint-up(md) {
            font-size: var(--font-size-20);
            }
            
        }
    }

    &__img {
        width: 54%;
        margin: 0 auto;
        margin-top: 40px;
margin-bottom: 30px;
        @include media-breakpoint-up(lg) {
            margin-top: 96px;
            width: auto;
            margin-bottom: 50px;


        }
    }


    .MuiTabPanel-root {
        // border-top: 1px solid #e2e9f5;
    }

    .MuiBox-root {
        margin-bottom: 0;
    }

    .MuiTabs-root {
        padding: 0;

        .MuiTabs-flexContainer {
            @include media-breakpoint-up(md) {
                gap: 30px;
            }
        }

        .css-cjctlb-MuiButtonBase-root-MuiTab-root {
            color: var(--gray-90);
            font-size: var(--font-size-14);
            padding: 12px 12px;
            text-transform: capitalize;

            @include media-breakpoint-up(md) {
                font-size: var(--font-size-base);

            }

            opacity: 1;
            font-family: var(--font-family--medium);

            &.Mui-selected {
                // background-color: rgba(0, 124, 222, 0.05);

                font-family: var(--font-family--bold);
            }
        }

        .PrivateTabIndicator-root-1.PrivateTabIndicator-colorSecondary-3.MuiTabs-indicator {
            background-color: #007CDE;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            height: 4px;
        }
    }







    .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {

        width: 64px;
        height: 64px;
    }

    .css-14yr603-MuiStepContent-root {
        margin-left: 21px;
        padding-left: 40px;
        border-left: 2px solid #bdbdbd;

        @include media-breakpoint-up(md) {
            margin-left: 29px;
            border-left: 8px solid #bdbdbd;
            padding-left: 50px;
        }


        padding-right: 8px;

    }
    .css-1mz1l2x-MuiStepContent-root{
        margin-left: 25px;
        padding-left: 40px;
      

        @include media-breakpoint-up(md) {
            margin-left: 46px;
        }
    }

    .css-vnkopk-MuiStepLabel-iconContainer {
        padding-right: 24px;
    }

    .css-8t49rw-MuiStepConnector-line {
        border-color: #bdbdbd;
        border-left-width: 2px;
        @include media-breakpoint-up(md) {
            border-left-width: 8px;
        }
    }

    .css-1hv8oq8-MuiStepLabel-label {

        font-size: var(--font-size-14);
        font-family: var(--font-family--bold);
        color: #2A3342;
        line-height: 26px;

        @include media-breakpoint-up(md) {
            line-height: 36px;
            font-size: var(--font-size-24);
        }
    }

    .css-ahj2mt-MuiTypography-root {
        font-size: var(--font-size-14);
        font-family: var(--font-family--medium);
        color: #556987;
        line-height: 20px;

        @include media-breakpoint-up(md) {
            line-height: 30px;
            font-size: var(--font-size-20);
        }
    }

    .css-14sza3e-MuiStepLabel-root {
        padding: 0;
    }

    .css-1pe7n21-MuiStepConnector-root {
        -webkit-flex: 1 1 auto;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        margin-left: 21px;
        @include media-breakpoint-up(md) {
            margin-left: 29px;
        }
    }

    .MuiStepLabel-iconContainer>div {
        width: 40px;
        height: 40px;

        @include media-breakpoint-up(md) {
            width: 62px;
            height: 62px;
        }

        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 2px solid #007CDE;

        &.makeStyles-active-2 {
            
        }
    }
    .MuiStepLabel-iconContainer.Mui-active>div{
        background-color: #007CDE;
    }
    .MuiTabPanel-root {
        padding: 24px 0px;
    }

}
.service-stepper{
    background-color: #fff;
    // margin-top: 25px;
    margin-bottom: 50px;
    &:before {
        content: "";
        height: 48px;
        width: 100%;
        position: absolute;
        z-index: 0;
        background-color: #F8F9FA;
    }
    .MuiTabPanel-root {
        margin-top: 6px;
        @include media-breakpoint-up(md) {
       margin-top: 25px;
        }
    }

    .career__info-title {
        padding: 24px 0 10px;
font-size: 14px;
font-family: var(--font-family--bold);
        @include media-breakpoint-up(md) {
        padding: 30px 0 24px;
        font-size: 24px;
        }
    }
.career__info-list{
    line-height: 20px;
    margin-bottom: 14px;
    @include media-breakpoint-up(md) {
        line-height: 30px;
        margin-bottom: 8px;
    }

}

}