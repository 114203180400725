
@import "../../mixins";
.dashboard-layout{
    display: flex;
    position: relative;
    
    &__right{
        padding: 24px 16px;
        background-color: var(--bg);
        @include media-breakpoint-up(lg) {
            padding: 40px 24px;
        }
        @include media-breakpoint-up(xl) {
        width: calc(100% - 264px);
        }
       
   

    }
    &__left{
        @include media-breakpoint-down(xl) {
        position: fixed;
        bottom: 0;
        width: 100%;
        z-index: 999;
        }
        @include media-breakpoint-up(xl) {
            background-color: #f7f8f9;
            position: sticky;
            top: 10px;
            height: 100%;
        }
    }
    

}
.box-border{
    border: 1px solid var(--gray-1);
    box-shadow: 0 20px 24px -4px #2A334208;
    border-radius: 4px;
}
