@import "../../mixins";

.customer-info {
  // padding-top: 40px;
  background-color: var(--bg);
  padding-bottom: 40px;
  &__title {
    font-size: var(--font-size-base);
    font-family: var(--font-family--bold);
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @include media-breakpoint-up(xl) {
      flex-wrap: nowrap;
    }
  }

  &__left {
    width: 100%;
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 6px 7px 6px rgba(85, 105, 135, 0.08);
    min-height: 600px;
    padding: 24px 32px;
    // @include media-breakpoint-up(lg) {
    //   width: calc(100% - 340px);
    // }
    @include media-breakpoint-up(xl) {
      width: calc(100% - 356px);
    }
    @include media-breakpoint-up(xxl) {
      width: calc(100% - 362px);
    }
  }

  &__right {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0px 6px 7px 6px rgba(85, 105, 135, 0.08);
    min-height: 600px;
    padding: 24px;
    width: 100%;
    // @include media-breakpoint-up(lg) {
    //   width: 340px;
    // }
    @include media-breakpoint-up(xl) {
      width: 356px;
    }
    @include media-breakpoint-up(xxl) {
      width: 362px;
    }
  }

  &__block-title {
    font-size: var(--font-size-base);
    font-family: var(--font-family--bold);
    padding-bottom: 16px;
  }
  hr {
    margin-bottom: 24px;
    color: var(--gray-200);
  }
  .time-picker-class {
    .MuiInputBase-root {
      height: 45px;
    }
  }
  .service-package {
    background-color: transparent !important;
  }
  .MuiFormGroup-root.MuiFormGroup-row.css-qfz70r-MuiFormGroup-root {
    gap: 5px;
    @include media-breakpoint-up(md) {
    gap: 24px;
    }
  }
}

.form {
  &__row {
    display: flex;

    flex-wrap: wrap;
    margin-top: 16px;
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      margin-top: 0;
    }
    gap: 16px;
    @include media-breakpoint-up(xl) {
      gap: 24px;
    }
    &--three-col {
      .form__group {
        // flex:auto;
        // @include media-breakpoint-up(lg){
        // flex: 0 1 31.59%;
        // }
      }
    }
  }
  &__input {
    @include placeholder {
      font-size: var(--font-size-14);
      @include media-breakpoint-up(md) {
      font-size: var(--font-size-base);
      }
      color: var(--gray-500);
    }
   
    border: 1px solid var(--gray-200);
    padding: 0px 10px;
    font-size: var(--font-size-14);
    height: 44px;
    @include media-breakpoint-up(md) {
      height: 46px;
      padding: 0px 8px;
      font-size: var(--font-size-base);
    }
    @include media-breakpoint-up(lg) {
      padding: 0px 10px;
    }
    border-radius: 8px;

    box-shadow: 0px 1px rgba(0, 0, 0, 0.05);
   

    &:focus-visible {
      outline: none;
    }

    &:focus {
      border-color: var(--blue-1);
    }

    &--textarea {
      padding: 16px;
    }

    &--checkbox {
      height: auto;
    }
  }

  &__label {
    text-align: left;
    font-family: var(--font-family--medium);
    font-size: var(--font-size-14);
    line-height: 20px;
  }

  &__input-error {
    color: red;
    font-size: 12px;
    p {
      padding: 0;
      margin: 0;
    }
  }

  &__actions {
    display: flex;
    gap: 16px;
    @include media-breakpoint-down(md) {
      margin-top: 24px;
    }
    .button {
      border: 1px solid var(--blue-2);
      &:hover {
        background-color: var(--white);
        color: var(--blue-1);
        border: 1px solid var(--blue-2);
      }
    }
  }

  &__button {
    margin-top: 24px;
    cursor: pointer;
    font-size: var(--font-size-base);
    background-color: var(--blue-2);
    border: 1px solid var(--blue-2);
    color: var(--white);
    padding: 10px 20px;
    border-radius: 8px;
    width: calc(100% - 130px);

    &:hover {
      background-color: var(--white);
      color: var(--blue-1);
      border: 1px solid var(--blue-2);
    }
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 0 1 100%;
    margin-bottom: 5px;
    @include media-breakpoint-up(md) {
      flex: 0 1 38%;
      margin-bottom: 16px;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 1 31.5%;
    }
    @include media-breakpoint-up(xl) {
      flex: 0 1 31.5%;
    }
    &--input {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__group-input {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;

    input {
      height: auto;
      cursor: pointer;

      &:nth-child(2) {
        margin-left: 18px;
      }
    }
  }
  &__group:not(:nth-child(3)) {
    // margin-right: 16px;
  }
}
.text-bold {
  font-family: var(--font-family--semibold);
}
.pos-relative {
  position: relative;
}
.change-phone {
  position: absolute;
  right: 16px;
  top: 39px;
  font-size: var(--font-size-14);
  color: var(--blue-1);
  font-family: var(--font-family--medium);
  text-transform: uppercase;
}
.bg-icon-left {
  background-repeat: no-repeat;
  background-position: 12px;
  padding-left: 40px;
  @include media-breakpoint-up(lg) {
    padding-left: 38px;
  }
}
.msg-icon {
  @extend .bg-icon-left;
  background-image: url("../../../public/images/msg.svg");
}
.tel-icon {
  @extend .bg-icon-left;
  background-image: url("../../../public/images/tel.svg");
}
.map-icon {
  @extend .bg-icon-left;
  background-image: url("../../../public/images/map.svg");
}
.date-icon {
  @extend .bg-icon-left;
  background-image: url("../../../public/images/date-icon.svg");
}
.clock-icon {
  @extend .bg-icon-left;
  background-image: url("../../../public/images/clock.png");
}

input:-internal-autofill-selected {
  background-color: white;
}

button:disabled {
  cursor: not-allowed;
}
