@import "../../mixins";

.home {
  background-repeat-y: no-repeat;
  background-image: url("../../../public/images/Union.svg");
  background-position: 2px 0;

  &__block {
    display: flex;
    justify-content: space-between;
    padding: 100px 40px 10px;
    flex-wrap: wrap;
    padding: 20px 40px 10px;
    margin-bottom: 40px;
    @include media-breakpoint-up(lg) {
      padding: 136px 40px 10px;
      flex-wrap: nowrap;
      margin-bottom: 0;
    }
  }

  &__content {
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: calc(100% - 438px);
    }
    max-width: 769px;
  }

  &__title {
    color: var(--white);
  }

  &__text {
    font-family: var(--font-family--medium);
    font-size: var(--font-size-base);
    margin-bottom: 25px;
    @include media-breakpoint-up(lg) {
    font-size: var(--font-size-24);
    margin-bottom: 1rem;
    }
    line-height: 30px;
    color: var(--white);
  }
  &__bike-man{
    @include media-breakpoint-down(md) {
    max-width: 340px;
    margin: 0 auto;
    }
  }

  &__booking {
    margin: 0 auto 10px;
    height: 470px;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 438px;
      margin: 0 auto 70px;
      height: 509px;
    }
    @include media-breakpoint-up(lg) {
      margin: 0;
    }
    padding: 24px 40px;
    border-radius: 16px;
    background-color: var(--white);


    position: relative;
    box-shadow: 0px 15px 20px 0px rgba(85, 105, 135, 0.3);
    .extra-info {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-family: var(--font-family--regular);
      font-size: var(--font-size-14);
      span{
        font-size: 12px;
        line-height: 24px;
        color: var(--gray-500);
        font-family: var(--font-family--medium);
        a{
          text-decoration: none;
        }
      }
    }
  }

  &__logoicon {
    position: absolute;
    z-index: 1;
    top: -30px;
    text-align: center;
    display: flex;
    width: calc(100% - 80px);
    justify-content: center;
  }

  &__booking-title {
    color: var(--gray-900);
    margin-bottom: 4px;
    margin-top: 40px;
    text-align: center;
  }

  &__booking-quote {
    color: var(--gray-500);
    line-height: 28px;
    font-family: var(--font-family--medium);
    @include media-breakpoint-up(lg) {
    font-size: var(--font-size-18);
    }
    text-align: center;
  }
}

.containerstyle {
  margin-bottom: 24px;

  .inputstyle {
    padding: 10px 12px;
    border: 1px solid var(--gray-200);
    color: var(--black);
    font-family: var(--font-family--medium);
    font-size: var(--font-size-base);
    border-radius: 8px;
    width: 60px !important;
    @include media-breakpoint-up(md) {
      width: 72px !important;
    }
    height: 46px;

    &:focus-visible {
      outline: transparent auto 0;
    }
  }

  span {
    display: inline-block;
    width: 24px;
  }
}

.verify-action {
  margin-bottom: 24px;
}

.otp-title {
  margin: 30px 0;
  color: var(--gray-900);
  font-size: var(--font-size-18);
  line-height: 24px;
  text-align: center;
  font-family: var(--font-family--bold);
  span {
    color: var(--blue-1);
    font-size: var(--font-size-12);
    cursor: pointer;
    font-family: var(--font-family--normal);
  }
}

.resend-text {
  margin-top: 24px;
  font-size: 14px;
  font-family: var(--font-family--medium);
  color: var(--gray-800);
  line-height: 20px;
  text-align: center;

  .resend-link {
    color: var(--blue-1);
    text-decoration: none;
    cursor: pointer;
  }
}

.phone-input {
  .selected-flag {
    pointer-events: none;
    .arrow {
      display: none;
    }
  }
}
.home__booking {
  input::selection {
    background: #fff; /* WebKit/Blink Browsers */
  }
  input::-moz-selection {
    background: #fff; /* Gecko Browsers */
  }
  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    transform: translate(14px, 12px) scale(1);
    color: #8896AB;
  }
}

.visbility-hidden {
  visibility: hidden;
}
.phone-input{
  p{
    margin-top: 6px!important;
    text-align: left;
  }
}