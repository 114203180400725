@import "../../../mixins";
.getintouch{
    background-color: var(--blue-1);
    position: relative;
    height: 249px;
    margin-top: 85px;
    &__block{
        background: #132845;
        position: absolute;
      
        left: 0;
        right: 0;
        padding: 64px 16px;
        text-align: center;

        top: -108px;
        max-width: 1100px;
        width: 85%!important;
        @include media-breakpoint-up(md) {
        top: -145px;
        width: 100%!important;
        }


        h1{
            color: var(--white);
            @include media-breakpoint-down(md) {
            line-height: 30px;
            font-size: var(--font-size-20);
            }
            @include media-breakpoint-up(md) {
            line-height: 60px;
            }
            letter-spacing: -2%;
            font-family: var(--font-family--bold);
           
        }
        p{
            color: var(--gray-400);
            margin-top: 0px;
            line-height: 20px;
            font-size: var(--font-size-base);

            @include media-breakpoint-up(md) {
            font-size: var(--font-size-20);
            line-height: 30px;
            }
          
        }
    }
}
