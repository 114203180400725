@import "../../../mixins";

// @import "../../../global";

.service-dashboard {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include media-breakpoint-up(lg) {
      align-items: baseline;
    }
  }

  &__jobsheet {
    &.jobsheet {
      &:hover {
        background-color: white !important;
        svg path {
          fill: var(--blue-2) !important;
        }
      }
    }
    display: flex;
    // opacity: 0.5;
    @include media-breakpoint-down(lg) {
      border: 0;
      background-color: transparent;
    }

    &:hover {
      color: var(--blue-2);
      svg path {
        fill: var(--blue-2);
      }
    }
  }
}

.service-info {
  display: flex;
  justify-content: space-between;

  padding: 24px;
  background-color: var(--white);
  border: 1px solid #e2e9f5;
  border-radius: 6px;
  box-shadow: 0px 4px rgba(42, 51, 66, 0.02);
  margin: 0px 0 16px;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  @include media-breakpoint-up(md) {
    margin-top: 0;
  }
  @include media-breakpoint-up(lg) {
    flex-direction: row;
    margin: 24px 0 16px;
    gap: 0;
  }

  &__item {
    display: flex;
    gap: 16px;
  }

  &__label {
    @extend.sm-text-light;
    margin-bottom: 2px;
  }

  &__data {
    @extend .lg-text-dark;
  }
}

.service-details {
  display: flex;
  gap: 16px;
  flex-direction: column;
  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  &__left {
    background-color: var(--white);
    border: 1px solid #d6dfeb;
    border-radius: 6px;
    box-shadow: 0px 4px rgba(42, 51, 66, 0.02);
    margin: 0 0 16px;
    flex: 0 1 78%;
  }

  &__right {
    padding: 24px;
    background-color: var(--white);
    border: 1px solid #e2e9f5;
    border-radius: 6px;
    box-shadow: 0px 4px rgba(42, 51, 66, 0.02);
    margin: 0 0 16px;
    flex: 0 1 28%;
  }

  &__register {
    display: flex;

    gap: 16px;
    border-bottom: 1px solid #d6dfeb;
    padding: 24px;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      gap: 54px;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__register-item {
    display: flex;
    align-items: center;
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
    gap: 10px;
  }

  &__register-label {
    @extend .sm-text-light;
  }

  &__register-value {
    @extend .sm-text-dark;
  }

  &__item-block {
    //margin-bottom: 4px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  &__item {
    padding: 24px;
    border-bottom: 1px solid #d6dfeb;
    @include media-breakpoint-up(md) {
      border-right: 1px solid #d6dfeb;
    }
  }
  &__item-img{
    max-width: 200px;
    margin: 0 auto;
  }

  &__progress {
    .progress-bar {
      width: 100% !important;
      @include media-breakpoint-up(md) {
        width: 164px !important;
      }
      margin-bottom: 22px;
    }

    .progress-block {
    }

    .progress-bar-label {
      text-align: left;
      @extend .sm-text-light;
      font-family: var(--font-family--primary);
    }

    .progress-item {
      //display: none;
    }
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-43 {
  margin-bottom: 23px;
}

.address {
  &__pickup {
    p {
      @extend.sm-text-light;
      margin: 0;
      color: #4d5f7a;
    }
  }
}

.MuiStepper-root {
  .MuiStepConnector-root.MuiStepConnector-vertical.Mui-completed {
    .MuiStepConnector-line {
      border-color: #007cde;
      border-width: 2px;
    }
  }

  .MuiStepConnector-root.MuiStepConnector-vertical.Mui-active {
    .MuiStepConnector-line {
      border-color: #007cde;
      border-width: 2px;
    }
  }

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active {
    border: 2px solid #007cde;
    color: white;
    border-radius: 100%;
  }

  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiStepIcon-root.Mui-active {
    .MuiStepIcon-text.css-117w1su-MuiStepIcon-text {
      fill: #007cde;
    }
  }
}

.service-addon {
  background-color: var(--white);
  border: 1px solid #d6dfeb;
  border-radius: 6px;
  box-shadow: 0px 4px rgba(42, 51, 66, 0.02);

  // margin: 24px 0 16px;
  &__left {
    padding: 24px;
    border-bottom: 1px solid #d6dfeb;
    @include media-breakpoint-up(md) {
      border-right: 1px solid #d6dfeb;
    }
    flex: 0 1 72%;
  }
  &__right {
    flex: 0 1 28%;
  }

  &__inner {
    background-color: var(--white);
    border: 1px solid #d6dfeb;
    border-radius: 6px;
    box-shadow: 0px 4px rgba(42, 51, 66, 0.02);
    display: inline-flex;
    margin-right: 20px;
  }

  &__item {
    display: flex;
    gap: 16px;
  }

  &__item-img {
    background-color: #e2e9f5;
    width: 56px;
    height: 56px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__item-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px 16px 6px 0;
  }

  &__total {
    padding: 24px;
  }

  &__block {
    display: flex;

    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .service-details__item-block {
    display: flex;
    gap: 16px;
    justify-content: space-between;
  }

  .service-details__item {
    padding: 0;
    border: 0;
  }

  .discount {
    color: #3575dd;
  }
  .service-dashboard__jobsheet {
    display: flex;
    width: 100%;
    gap: 16px;
    justify-content: center;
    border: 1px solid #2f80ed;
    background-color: white;
  }
}
