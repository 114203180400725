@import "../../../mixins";

.howitwork {
    margin: 60px 0;
    @include media-breakpoint-up(lg) {
        margin: 180px 0;
    }
    .container {
        max-width: 1100px;
    }

    .slider__item-block {
        display: flex !important;
        align-items: center;
        flex-wrap: wrap;

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
        }
    }

    .slider__left {
        text-align: center;

        @include media-breakpoint-up(lg) {
            padding-right: 20px;
            text-align: left;
        }
    }

    h3 {
        font-size: 20px;
        @include media-breakpoint-up(md) {
        font-size: 24px;
        }

        color: var(--blue-1);
        line-height: 32px;
        font-family: var(--font-family--bold);
    }

    .slider__item-title {
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 16px;
        @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 24px;
        }
        @include media-breakpoint-up(lg) {
        font-size: 48px;
        line-height: 60px;
        }
        color: #2A3342;
     
        font-family: var(--font-family--bold);
        letter-spacing: -2%;
       
    }

    .slider__item-text {
        font-size: 16px;
        line-height: 26px;
        @include media-breakpoint-up(md) {
        font-size: 20px;
        line-height: 30px;
        }
        color: var(--gray-500);
     
        font-family: var(--font-family--medium);
        letter-spacing: -2%;
    }

    .slider__right {
        width: 100%;
        text-align: center;

        @include media-breakpoint-up(lg) {
            width: auto;
            text-align: left;
        }
    }
    .slider__item-image{
        svg{
            width: 350px;
            @include media-breakpoint-up(lg) {
                width: auto
            }
        }
    }

}

.slick-prev {
    display: none!important;
    @include media-breakpoint-up(lg) {
    height: 48px;
    width: 48px;
    left: -110px;
    }

    &:before {
        content: "";
        opacity: 0;
        @include media-breakpoint-up(lg) {
            background-image: url("../../../../public/images/leftArrow.svg");
            content: "";
            height: 48px;
            width: 48px;
            opacity: 1;
            display: block;
        }

       
       
    }

}

.slick-next {
    display: none!important;
    @include media-breakpoint-up(lg) {
    height: 48px;
    width: 48px;
    right: -110px;
    }
    &::before {
        opacity: 0;
        content:'';
        @include media-breakpoint-up(lg) {
            opacity: 1;
            height: 48px;
        width: 48px;
        display: block;
        opacity: 1;
            background-image: url("../../../../public/images/rightArrow.svg");
        }

      
        
    }
}