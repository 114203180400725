@import "../../../../mixins";
// @import "../../../../global";

.history-table {
  padding: 5px 24px;
  border-top: 1px solid #e2e9f5;
  // width: 342px;
  // @media screen and (min-width:425px) {
  //   width: 390px;
  // }
  // @include media-breakpoint-up(sm){
  //   width: 375px;
  // }
  // @include media-breakpoint-up(md){
  //   width: auto;
  // }
  // @include media-breakpoint-up(lg){
  //   width: auto;
  // }
  // @include media-breakpoint-up(xl){
  //   width: auto;
  // }

  table {
    // border-collapse: collapse;
    border-collapse: separate; 
    border-spacing: 0 10px; 
    margin-top: -10px;
    min-width: 1000px; 
  }

  td:first-child{
    border-left: 1px solid #e2e9f5;
    border-radius: 10px 0 0 10px;
  }
  td:last-child{
    border-right: 1px solid #e2e9f5;
    border-radius: 0 10px 10px 0;
  }
th{
    @extend .xs-text-light;
    font-family: var(--font-family--primary);
    color: var(--gray-500);
    border-bottom: 0!important;
}
  td {
    @extend .sm-text-dark;
    font-family: var(--font-family--medium);
    margin-bottom: 10px;
    padding: 10px 14px 10 0;
    vertical-align: middle;
    border: solid 1px #e2e9f5;
    border-style: solid none;
    // border-bottom: 1px solid #e2e9f5;
    // border-top: 1px solid #e2e9f5;
    

    button {
      padding: 0;
      border: 0;
      font-size: var(--font-size-14);
      cursor: pointer;

      &:hover {
        background-color: transparent;
        color: #2f80ed;
        border: 0;
      }
    }

    .downarrow {
      background-image: url("../../../../../public/images/down-arrow.svg");
      width: 21px;
      height: 20px;
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.expanded {
  background-color: #f7f8f9;
  // padding: 0 24px;
  display: flex;
  &__left {
    flex: 0 1 84%;
    border-left: 1px solid #e2e9f5;
  }
  &__left-top {
    display: flex;
    padding: 8px 24px;
    gap: 40px;
  }
  &__left-bottom {
    padding: 8px 24px;
    display: flex;
    gap: 40px;
    border-top: 1px solid #e2e9f5;
  }
  &__right {
    flex: 0 1 16%;
    border-left: 1px solid #e2e9f5;
    padding: 8px 24px;
    border-right: 1px solid #e2e9f5;
  }
  &__item {
    max-width: 300px;
    ol {
      margin: 0;
      padding: 0;
    }
  }
  &__item-data {
    @extend .sm-text-dark;
    color: var(--gray-900);
  }
  &__item-title {
    @extend .xs-text-light;
    color: var(--gray-500);
  }
}
.expanded-td {
  padding: 0 !important;
}
.table-responsive{
    // width:400px;
    overflow-x:auto;
    table{
        width: 100%;
    }
    // @include media-breakpoint-up(md){
    //     width: 686px;
    // }
    // @include media-breakpoint-up(lg){
    //     width: auto;
    // table{
    //     width: auto;
    // }
    // }
    
}