
.terms{
    margin-bottom: 70px;
    .container{
        max-width: 1100px;
    }
    .mb-10{
        margin-bottom: 10px;
    }
}
.terms-container {
    // font-family: 'Open Sans', sans-serif;
    // color: #262626;
  }
  
  .terms-title h1{
    font-size: 36px;
    font-family:var(--font-family--bold);
    text-align: center;
    font-weight: bold;
    letter-spacing: 4px;  
    margin: 50px;
  }
  
  .terms-body h4 {
    color: #262626;
    font-size: 16px;
    line-height: 28px;
    // padding: 12px 0;
  }
  
  .terms-body h3{
    font-family:var(--font-family--bold);
    color: #262626;
    font-weight: bold;
    text-align: left;
    margin-bottom: -10px;
    font-size: 24px;
 margin: 30px 0;
  }
  h2{
    margin-bottom: 28px;
    margin-top: 28px;
  }
  
//   .terms-body p{
//     font-size: 12px;
//     line-height: 25px
//   }
  
//   .terms-body a{
//     color: #EB573D;
//   }
  
//   .terms-body a:hover{
//     color: #EB573D;
//     text-decoration: none;
//   }
  
  .terms_footer h3 {
    margin-top: 60px;
    margin-bottom: 125px;
    text-align: center;
    font-weight: bold;
    letter-spacing: 3px;
    line-height: 1.5;
    color: #262626;
  }
  
  