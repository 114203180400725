@import "../../../mixins";

.add-garage {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e2e9f5;

  &__block-title {
    font-size: var(--font-size-base);
    font-family: var(--font-family--bold);
  }
  .form__actions {
    border-top: 1px solid var(--gray-200);
    padding-top: 16px;
    .button--disabled {
      pointer-events: all;
    }
  }
}

.bg-icon-left {
  background-repeat: no-repeat;
  background-position: 12px;
  padding-left: 32px;

  @include media-breakpoint-up(lg) {
    padding-left: 38px;
  }
}

.map-icon {
  @extend .bg-icon-left;
  background-image: url("../../../../public/images/map-gray.svg");
}

.file-uploader {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #455560;
  gap: 10px;
  max-width: 604px;
}

.file-uploader .file-upload-label {
  font-style: normal;
  font-weight: 400;
  font-size: 1.167rem;
  line-height: 14px;
  margin-top: 24px;
}

.file-uploader .file-upload-button {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  height: 88px;
  gap: 8px;
  border: 1px solid var(--gray-200);
  border-radius: 8px;
  cursor: pointer;
}

.file-uploader .file-upload-caption {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #455560;
}

.uploaded-file-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #455560;
  gap: 10px;
}

.uploaded-file-list .uploaded-file-list-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #455560;
}

.uploaded-file-list-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  justify-content: space-between;
}

.uploaded-file-details {
  display: flex;
  gap: 0;
  align-items: center;
}

.uploaded-file-list-box .uploaded-file-name-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px 24px;
}

.uploaded-file-list-box .uploaded-file-details .uploaded-file-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  color: #1a1c1f;
}

.uploaded-file-list-box .uploaded-file-details .uploaded-file-size {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #455560;
}

.uploaded-file-list-box .uploaded-file-actions {
  display: flex;
  gap: 10px;
}

.uploaded-file-list-box .uploaded-file-actions .uploaded-file-actions-icon {
  cursor: pointer;
}

.upload-btn-wrap {
  padding: 10px 24px;
  display: flex;
  gap: 24px;
  align-items: center;
}

.form__actions-btn {
  margin-bottom: 16px;
  label {
    margin-bottom: 6px;
  }
}

.upload-btn-text {
  color: #1c232d;
  font-family: var(--font-family--medium);
  font-size: var(--font-size-base);
}

.upload-btn-text-size {
  font-size: 12px;
  line-height: 22px;
  color: #828282;
}
.accessory_type {
  display: flex;
  align-items: center;
  gap: 10px;
}
.add__container {
  border: 1px solid var(--blue-1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
