@import "../../mixins";
.MuiStepLabel-root {
  &.MuiStepLabel-horizontal {
    display: flex;
    flex-direction: column;
  }
}

.service-package {
  background-color: var(--gray-050);
  padding-bottom: 60px;
  @include media-breakpoint-up(md) {
    padding-bottom: 60px;
  }
  &__title {
    color: var(--gray-900);
    text-align: center;
    @include media-breakpoint-up(md) {
    margin-top: 46px;
    }
  }

  &__text {
    color: var(--gray-500);
    margin-bottom: 25px;
    line-height: 16px;
    font-size: var(--font-size-base);
    @include media-breakpoint-up(md) {
      margin-bottom: 34px;
    font-size: var(--font-size-20);
    line-height: 20px;
    }
    text-align: center;
   
  }

  &__block {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__item {
    @include media-breakpoint-up(md) {
      flex: 0 1 26.4%;
    }

    background-color: var(--white);
    box-shadow: 0px 1px rgba(85, 105, 135, 0.1);
    border-radius: 2px;
    padding: 24px;
    border-radius: 6px;
  }
  &__item-title {
    color: var(--gray-500);
    list-style: none;
    line-height: 30px;
    margin-bottom: 22px;
    font-size: var(--font-size-20);
    font-family: var(--font-family--medium);
    text-align: center;
  }
  &__item-price {
    text-align: center;
    color: var(--gray-900);
    line-height: 83%;
    letter-spacing: -2px;
    margin-bottom: 22px;
    font-size: 56px;
    @include media-breakpoint-up(md) {
    font-size: var(--font-size-72);
    }
    font-family: var(--font-family--semi-bold);
  }
  &__item-list-block {
    padding-left: 0;
  }
  &__item-list {
    color: var(--gray-500);
    list-style: none;
    margin-bottom: 12px;
    font-family: var(--font-family--medium);
    line-height: 24px;
    font-size: var(--font-size-base);
  }
  &__item-action {
    text-align: center;
    margin-bottom: 24px;
    button {
      width: 100%;
    }
  }
  &__item-more {
    color: var(--info-600);
    cursor: pointer;
    padding-left: 30px;
    font-family: var(--font-family--medium);
  }
  &__recomm {
    text-align: center;
  }
  .mb-5 {
    margin-bottom: 5px;
  }
  &__recomm-text {
    color: var(--white);
    font-size: 12px;
    line-height: 18px;
    background-color: var(--blue-1);
    font-family: var(--font-family--medium);
    display: inline-block;
    padding: 0px 6px;
  }
  &__block-text {
    color: var(--gray-400);
    margin-bottom: 12px;
    font-family: var(--font-family--medium);
    line-height: 24px;
    font-size: var(--font-size-base);
    margin-top: 0;
    @include media-breakpoint-up(md) {
    margin-top: 24px;
    }
    margin-bottom: 16px;
  }
}

.list-icon {
  background-image: url("../../../public/images/listicon.svg");
  background-repeat: no-repeat;
  padding-left: 32px;
}

.css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  font-size: 12px;
  font-family: var(--font-family--primary);
}
.MuiStepper-horizontal {
  max-width: 577px;
  padding-top: 40px;
  margin: 0 auto;
}
