@import "../../../mixins";

.leftsidebar {
    background-color: #f7f8f9;

    @include media-breakpoint-up(xl) {
        width: 264px;
        padding: 40px 24px;
        height: 96vh;
    }

    padding: 0px 24px;

    &__nav {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(md) {
            gap:20px;
        }
        @include media-breakpoint-up(xl) {
            flex-direction: column;
           
            height: calc(100vh - 140px);
            align-items: unset;
        }

        justify-content: space-between;

    }

    &__nav-list {
        list-style: none;
        display: flex;
        gap: 24px;
        @include media-breakpoint-down(md) {
            gap: 5px;
            justify-content: space-between;
            width: 100%;
        }
     
        @include media-breakpoint-up(xl) {
            flex-direction: column;
            gap: 0;

        }

        padding-left: 0;
    }

    &__nav-link {
        height: 44px;
        display: flex;
        gap: 10px;
        background: white;

        @include media-breakpoint-up(xl) {
            margin-bottom: 8px;
        }

        border-radius: 6px;
        align-items: center;
        text-decoration: none;
        padding: 0 14px;
        border: 2px solid white;
        color: #556987;
        font-size: 14px;
        @include media-breakpoint-up(md) {
        font-size: 16px;
        }
        line-height: 24px;
        @include media-breakpoint-down(lg) {
            padding: 0;
        border: 2px solid transparent;
        flex-direction: column;
        height: auto;
        background-color: transparent;
        }
        @include media-breakpoint-down(md) {
            gap: 5px;
        }
        &:hover,
        &.active {
            @include media-breakpoint-up(lg) {
            border: 2px solid #007CDE;
            }
            color: #007CDE;

            svg {
                
                path {
                fill: #007CDE;
            }
        }
        }

        &--logout {
            background-color: #F7F8F9;
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        svg{
            @include media-breakpoint-down(lg) {
                height: 25px;
            }
        }
    }
    .mob-hide{
        @include media-breakpoint-down(lg) {
        display: none;
        }
    }
}