@import "../../mixins";

.about {
    .container {
        max-width: 1100px;

    }

    &__block {
        padding-top: 40px;

        @include media-breakpoint-up(md) {
            padding-top: 65px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 100px;
        }

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }
    }


    &__title {
        font-size: 24px;
        line-height: 36px;

        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 60px;
        }

        color: #2A3342;

        font-family: var(--font-family--bold);
        letter-spacing: -2%;
        margin-bottom: 24px;
    }

    &__text {
        font-size: 14px;

        @include media-breakpoint-down(md) {
            order: 3;
        }

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }

        line-height: 24px;

        @include media-breakpoint-up(lg) {
            font-size: 20px;
            line-height: 30px;
            color: #556987;

        }
    }

    &__img {
        @include media-breakpoint-down(md) {
            order: 2;
            margin-top: 0;
            margin-bottom: 24px;
        }

        margin: 0 auto;
        margin-top: 64px;
        text-align: center;

        img {
            max-width: auto;
            height: auto;
        }
    }

    .trustedCompany {
        background-color: #fff;

        .service-gurantee__title {
            font-size: 18px;

            @include media-breakpoint-up(lg) {
                font-size: 36px;
            }
        }
    }
}

.team {
    padding: 44px 0 170px;
    background-color: #F7F8F9;
    margin-top: 40px;

    .container {
        max-width: 1100px;
    }

    &__block {
        @include media-breakpoint-up(sm) {
            display: flex;
        }

        gap: 32px;
        justify-content: center;

    }

    &__item {
        text-align: center;
        padding: 32px;
        border-radius: 6px;
        box-shadow: 1px 3px 20px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 32px;
        flex: 0 1 48%;

    }

    &__title {
        text-align: center;
        font-size: 18px;
        color: #2A3342;
        line-height: 44px;
        font-family: var(--font-family--bold);

        @include media-breakpoint-up(md) {
            margin-bottom: 16px;
            margin-top: 16px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 36px;
        }


    }

    .about-text {
        text-align: center;
        margin-bottom: 40px;
    }

    &__item-name {
        margin: 16px 0 0px;
        font-size: 18px;

        @include media-breakpoint-up(md) {
            font-size: 24px;
            margin: 24px 0 8px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 30px;
        }

        line-height: 38px;
        font-family: var(--font-family--semi-bold);

    }

    &__item-designation {
        color: #3575DD;
        font-size: 16px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }

        font-family: var(--font-family--medium);
        line-height: 28px;

    }

    &__item-info {
        margin: 12px 0;
    }
    &__picture{
        max-width: 124px;
        height: 110px;
        margin: 0 auto;
       
        border-radius: 100%;
        img{
            border-radius: 100%;
        }
    }
}

.about-getintouch {

    background-color: var(--blue-1);
    height: 150px;
    margin-top: 0;

    @include media-breakpoint-up(md) {
        background-color: #fff;
        height: 111px;
        margin-top: 85px;
    }

    position: relative;

    &__block {
        background: #132845;
        position: absolute;

        width: 90% !important;
        top: -108px;
        text-align: center;


        left: 0;
        right: 0;
        padding: 25px 24px;

        @include media-breakpoint-up(md) {
            top: -205px;
            width: 100% !important;
            text-align: left;
        }

        max-width: 1100px;

        @include media-breakpoint-up(lg) {
            padding: 64px 64px !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        h2 {
            color: var(--white);
            font-size: 20px;
            line-height: 26px;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 36px;
                line-height: 60px;
                text-align: left;
            }

            letter-spacing: -2%;
            font-family: var(--font-family--bold);

        }

        a,
        p {
            color: var(--gray-400);
            margin-top: 0px;
            font-size: var(--font-size-base);
            line-height: 24px;

            @include media-breakpoint-up(md) {
                font-size: var(--font-size-20);
                line-height: 30px;
            }
        }

        .button {
            height: 56px;
            color: #fff;

            &.open-positions {
                line-height: 33px !important;
            }

            &:hover {
                color: #3575DD;
            }
        }
    }

}