@import "../../../mixins";
.help-support-bg{
    background-color:white;
    padding: 0;
    .contact__top {
    background-color: var(--bg);
    }

    .contact__bottom.admin {
         top: -60px !important;
    }
    .contact__left{
        @include media-breakpoint-up(xl) {
        padding-left: 35px;
        }
    }
}
