@import "../../mixins";
.pending-order{
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
    position: sticky;
    top: 5vh;
    z-index: 9999;
    &__block{
        
        margin-bottom: 24px;
        background-color: #fff;
        border-radius: 6px;
        box-shadow: 3px 3px 13px -6px #333F51;
        display: flex;
        justify-content: space-between;

    }
    &__item{
        display: flex;
        gap: 40px;
    }
    &__left{
        padding: 16px;
    }
    &__right{
        padding: 16px;
        border-left: 1px solid #EEF0F3;
    }
    &__title{
        margin-bottom: 16px;
        color: var(--blue-1);
        font-size: 14px;
        font-family: var(--font-family--semi-bold);
    }
    .button{
        border: 1px solid var(--blue-1);

        &:hover {
            border: 1px solid var(--blue-1);
        }
    }
}