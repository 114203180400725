:root {
  --white: #ffffff;
  --black: #000000;
  --dark-blue: #132845;
  --gray-050: #f7f8f9;
  --gray-100: #eef0f3;
  --blue-150: #ebf3fe;
  --gray-200: #d5dae1;
  --gray-400: #8896ab;
  --gray-500: #556987;
  --gray-900: #2a3342;
  --gray-800: #333f51;
  --blue-1: #2f80ed;
  --blue-2: #0c82ee;
  --bg: #e5e5e5;
  --blue-3: #007cde1a;
  --blue-4: #007cde;
  --info-600: #3575dd;

  --gray-1: #e2e9f5;
  --gray-2: #f1f6ff;
  --gray-3: #e5e5e5;
  --gray-4: #f0fdf4;

  --red-1: #f16063;
  --green-1: #22c55e;
  --purple-1: #9b51e0;

  --color-bg2: #031d2a;
  --font-family--primary: "Poppins Regular";
  // --font-family--secondary: "Plus Jakarta Sans Regular";
  --font-family--light: "Poppins Light";
  --font-family--medium: "Poppins Medium";
  --font-family--semi-bold: "Poppins SemiBold";
  --font-family--bold: "Poppins Bold";

  --font-size-9: 9px;
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-base: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-30: 30px;
  --font-size-36: 36px;
  --font-size-72: 72px;
  --font-size-h1: 48px;
}

@import "./fontface";
@import "./mixins";
@import "./global";
