@import "../../mixins";
.home-dashboard {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
flex-wrap: nowrap;
  }
}
.body-left {
  flex: 0 1 100%;
  @include media-breakpoint-up(md) {
  flex: 0 1 75%;
  }
  &__content {
    margin-top: 16px;
  }
  &__serviceCard {
    margin-top: 145px;
    @include media-breakpoint-up(md) {
    margin-left: 20px;
    }
    display: flex;
    flex-direction: column;
    gap: 8px;
    .card-individual {
      width: 100%;
      @include media-breakpoint-up(md) {
      width: 274px;
      }
      height: 72px;
      background-color: var(--white);
      box-shadow: 0px 12px 16px -4px rgba(42, 51, 66, 0.03), 0px 4px 6px -2px rgba(42, 51, 66, 0.02);
      border-radius: 8px;
      border: 1px solid var(--gray-1);
      display: flex;
      gap: 20px;

      align-items: center;
      padding: 20px;
      &:hover {
        border: 1px solid var(--blue-1);
      }
      &__left {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--blue-1);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__right {
        .button-book {
          cursor: pointer;
          color: var(--blue-1) !important;
        }
        p {
          margin-bottom: 0;
          color: var(--gray-900);
          font-family: var(--font-family--bold);
          font-size: var(--font-size-16);
          &:last-child {
            color: var(--gray-800);
            font-family: var(--font-family--semi-bold);
            font-size: var(--font-size-14);
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }
  &__ongoing {
    margin-top: 90px;
    @include media-breakpoint-up(md){
    margin-top: 60px;
    }
    h6 {
      font-family: var(--font-family--semi-bold);
    }
    .ongoing-serviceCards {
      display: flex;
      flex-direction: row;
      gap: 16px;
      flex-wrap: wrap;
      margin-top: 16px;
      &__individual {
        width: 100%;
        @include media-breakpoint-up(md) {
        width: 273px;
        }
        min-height: 350px;
        border-radius: 8px;
        border: 1px solid var(--gray-1);
        display: flex;
        flex-direction: column;
        // &:hover {
        //   border: 1px solid var(--blue-1);
        // }
        .card-image {
          flex: 0 1 40%;
          background-color: var(--gray-2);
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          font-size: var(--font-size-12);
          font-family: var(--font-family--light);
          color: var(--white);
          border-bottom: 1px solid var(--gray-1);
          .status-completed {
            background-color: var(--green-1);
            max-width: fit-content;
            padding: 0 10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            text-align: center;
            margin: auto;
          }
          .status-ongoing {
            background-color: var(--blue-4);
            max-width: fit-content;
            padding: 0 10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            text-align: center;
            margin: auto;
          }
          .status-booked {
            background-color: var(--purple-1);
            max-width: fit-content;
            padding: 0 10px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            text-align: center;
            margin: auto;
          }
          .image-container {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 150px;
            object-fit: contain;
            aspect-ratio: 1;
            margin: auto;
          }
        }
        .card-content {
          flex: 0 1 100%;
          @include media-breakpoint-up(md) {
          flex: 0 1 60%;
          }
          background-color: var(--white);
          padding: 18px 20px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          .service-details {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            .id {
              font-size: var(--font-size-10);
              font-family: var(--font-family--semi-bold);
              color: var(--blue-1);
            }
            .date {
              font-size: var(--font-size-9);
              font-family: var(--font-family--light);
            }
          }
          .vehicle-details {
            font-size: var(--font-size-16);
            font-family: var(--font-family--bold);
          }
          .package-details {
            margin-top: 10px;
            .name {
              font-size: var(--font-size-12);
              font-family: var(--font-family--light);
              color: var(--gray-500);
            }
            .package {
              font-size: var(--font-size-12);
              font-family: var(--font-family--semi-bold);
              color: var(--gray-900);
            }
          }
        }
      }
    }
  }
  &__banner {
    display: flex;
    position: relative;
    height: 234px;
    margin-top: 20px;
    flex-wrap: wrap;
    @include media-breakpoint-up(md){
      flex-wrap: nowrap;
    }
  }
  &__image {
    flex: 0 1 100%;
    @include media-breakpoint-up(md){
    flex: 0 1 60%;
    }
  }
  .bike-img {
    img {
      width: 100%;
      height: 234px;
    }
  }
  .oil-img {
    position: absolute;
    top: 13%;
    right: 4%;
        @include media-breakpoint-up(md){
    left: 40%;
    right: auto;
        }
  }
  &__text {
    background-color: var(--color-bg2);
    flex: 0 1 100%;
    margin-top: -4px;
    text-align: center;
   
    @include media-breakpoint-up(md){
    flex: 0 1 67%;
    margin-top: 0px;
    text-align: left;
    padding: 0 0 0 80px;
    
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
   
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    div {
      font-family: var(--font-family--bold);
      color: var(--white);
    }
    p {
      font-family: var(--font-family--light);
      color: var(--white);
      margin: 0;
    }
  }

  .refer-button {
    border: 0.5px solid var(--gray-1);
    color: var(--gray-4);
    font-size: var(--font-size-base);
    font-family: var(--font-family--primary);
    height: 35px;
    width: 106px;
    background: none;
    border-radius: 10px;
    margin-top: 20px;
  }
}

.body-right {
  @include media-breakpoint-up(md){
    flex: 0 1 25%;
    margin-left: 20px;
  }
 
  &__timeline {
    width: 274px;
    height: 350px;
    margin-top: 60px;
    background: var(--white);

    border: 1px solid var(--gray-1);
    box-shadow: 0px 12px 16px -4px rgba(42, 51, 66, 0.03), 0px 4px 6px -2px rgba(42, 51, 66, 0.02);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    &:hover {
      border: 1px solid var(--blue-1);
    }
    .timeline-individual {
      flex: 0 1 50%;
      padding: 20px;
      padding-bottom: 0;
    }
    .test-header {
      font-family: var(--font-family--semi-bold);
      color: var(--blue-1);
      font-size: var(--font-size-14);
    }
    .vehicle-no {
      font-family: var(--font-family--bold);
      // color: var(--blue-1);
      font-size: var(--font-size-16);
    }
    .vehicle-model {
      font-family: var(--font-family--light);
      color: var(--gray-500);
      font-size: var(--font-size-12);
    }
    .expiry {
      font-family: var(--font-family--light);
      color: var(--red-1);
      font-size: var(--font-size-12);
    }
    .renew-button {
      text-align: center;
      cursor: pointer;
      margin-top: 13px;
      box-shadow: 0px 1px 3px rgba(42, 51, 66, 0.06), 0px 1px 2px rgba(42, 51, 66, 0.03);
      border-radius: 6px;
      padding: 10px;
      color: var(--blue-1);
      font-family: var(--font-family--medium);
      font-size: var(--font-size-12);
    }
  }
}
