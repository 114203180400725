@import "../../mixins";
.addOn {
  background-color: var(--gray-3);
  width: 100vw;
  padding: 40px 0;
  // overflow: hidden;
  &__block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
   
    @include media-breakpoint-up(xl) {
      flex-wrap:nowrap;
   
    }
  }
  &__sideBar {
    flex: 0 1 100%;
    margin-top: 24px;
    border-radius: 10px;
    @include media-breakpoint-up(xl) {
    flex: 0 1 23%;
    margin-top: 0;
    }
    box-shadow: 0px 6px 7px 6px rgba(85, 105, 135, 0.08);
  }
  &__container {
    overflow: hidden;
    background-color: var(--white);
    flex: 0 1 100%;
    @include media-breakpoint-up(xl) {
    flex: 0 1 75%;
   
    }
    border-radius: 10px;
    box-shadow: 0px 6px 7px 6px rgba(85, 105, 135, 0.08);
  }
  &__title {
    color: var(--gray-900);
    font-family: var(--font-family--bold);
    font-size: var(--font-size-24);
  }
  &__block-title {
    font-size: var(--font-size-base);
    font-family: var(--font-family--bold);
    padding: 16px 24px;
  }
  &__header {
    height: 60px;
    border-bottom: 1px solid var(--gray-100);
    display: flex;
    margin: 0 15px;
    width: 100%;
    overflow-x: auto;
    gap: 3px;
    padding-right: 23px;
  }
  &__tabs {
    height: 100%;
    display: flex;
    &__individual {
      color: var(--gray-900);
      font-family: var(--font-family--medium);
      font-size: var(--font-size-14);
      @include media-breakpoint-up(xl) {
      font-size: var(--font-size-base);
      }
      padding: 15px;
      height: 100%;
      position: relative;
      cursor: pointer;
      width: fit-content;
      white-space: nowrap;
      align-items: center;
      display: flex;

      &.active {
        background-color: var(--blue-3);
        font-family: var(--font-family--bold);
        // border-bottom: 4px solid var(--blue-4);
        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 4px;
          position: absolute;
          background-color: var(--blue-4);
          bottom: 0;
          left: 0;
          border-radius: 10px 10px 0 0;
        }
      }
    }
  }
  &__body {
    height: calc(100% - 60px);
    overflow: hidden;
    overflow-y: auto;
    padding: 0px 8px 30px 16px;
    @include media-breakpoint-up(md) {
    padding: 30px 0 30px 30px;
    }
    &::-webkit-scrollbar {
      background-color: #fff;
      width: 16px;
    }

    /* background of the scrollbar except button or resizer */
    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    /* scrollbar itself */
    &::-webkit-scrollbar-thumb {
      background-color: #babac0;
      border-radius: 16px;
      border: 4px solid #fff;
    }

    /* set button(top and bottom of the scrollbar) */
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
  &__cards {
    display: flex;
    gap: 16px;
    @include media-breakpoint-up(md) {
    gap: 30px;
    }
    flex-wrap: wrap;
  }
  &__card {
    cursor: pointer;
    flex: 0 1 47%;
    @include media-breakpoint-up(md) {
    flex: 0 1 31%;
    height: 290px;
    }
   
    border: 1px solid var(--gray-1);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    .MuiCheckbox-root {
      position: absolute;
      top: 0;
      right: 0;
    }
    &:hover {
      border: 2px solid var(--blue-2);
    }
    .card-image {
      height: 120px;
      @include media-breakpoint-up(md) {
      height: 160px;
      }
      background-color: var(--gray-2);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        @include media-breakpoint-up(md) {
        height: 100%;
        }
      }
    }
    .card-content {
      padding: 10px 10px;
      @include media-breakpoint-up(md) {
      padding: 10px 25px;
      justify-content: center;
      }
      height: calc(100% - 160px);
      display: flex;
      flex-direction: column;
    
      h2 {
        color: var(--gray-900);
        font-family: var(--font-family--bold);
        font-size: var(--font-size-14);
        @include media-breakpoint-up(md) {
        font-size: var(--font-size-20);
        }
        margin: 0;
      }
      p {
        color: var(--gray-800);
        font-family: var(--font-family--light);
        font-size: var(--font-size-14);
        margin: 0;
      }
      span {
        color: var(--gray-900);
        font-family: var(--font-family--bold);
        font-size: var(--font-size-20);
        display: block;
        margin-top: 10px;
      }
    }
  }
  .service-package {
    background-color: transparent !important;
  }
}
