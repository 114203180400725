@import "../../../../mixins";
.service-orders {
  &__details {
    padding:0 16px;
    display: flex;
    margin-bottom: 16px;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
    }
    &:last-child{
      margin-bottom: 0;
    }
   
    
    .sm-text-dark-blue{
        color: var(--blue-1);
        font-family: var(--font-family--semi-bold);
      }
      .xs-text-light{
        color: #556987;
      }
  }
  &__details-left {
    padding: 16px 0;
    display: flex;
    
    flex: 0 1 100%;
    gap:24px;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      border-right: 1px solid var(--gray-1);
      flex: 0 1 65%;
    }
    @include media-breakpoint-up(lg) {
      flex: 0 1 77%;
      flex-wrap: nowrap;
    }
    .service-orders__details-item{
      flex: 0 1 100%;
      @include media-breakpoint-up(md) {
      flex: 0 1 33.33%;
      }
      &:nth-child(2){
        flex: 0 1 100%;
        @include media-breakpoint-up(md) {
        flex: 0 1 25.33%;
        }
      }
      &:nth-child(3){
        flex: 0 1 100%;
        @include media-breakpoint-up(lg) {
        flex: 0 1 25.33%;
        }
      }
    }
  }
  &__details-right {
    display: flex;
    padding: 16px 0 16px 16px;
    flex-direction: column;
    flex: 0 1 100%;

    @include media-breakpoint-up(md) {
    flex: 0 1 35%;
    }
    @include media-breakpoint-up(lg) {
    flex: 0 1 23%;
    }
    .service-orders__details-item{
      .service-orders__details-item-block{
        width: 100%;
      .button{
        width: 100%;
        font-size: 14px;
        &.accept{
          border:1px solid #16A34A;
          color: #16A34A;
          &:hover{
            background-color: #16A34A;
            color: white;
          }
        }
        &.cancel{
          border:1px solid #EB5757;
          color: #EB5757;
          &:hover{
            background-color: #EB5757;
            color: white;
          }
        }
      }
    }
    }
    .towing-checkbox{
      font-size: 16px;
      font-family: var(--font-family--medium);
      color: #333F51;
      ;
    }
  }
  &__details-badge {
    border-radius: 36px;
    border: 1px solid var(--blue-2);
    color: var(--blue-2);
    font-size: 10px;
    font-family: var(--font-family--medium);
    padding: 2px 10px;
    display: inline-block;
    margin-bottom: 12px;
    margin-right: 10px;
  }
  &__details-item{
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  &__details-badge-img{
    width: 220px;
  }
  &__details-item-block{
    .button{
      width: 160px;
      font-size: 12px;
      padding: 7px 0;
      margin-bottom: 0;
      &:hover{
        border: 1px solid var(--blue-2)
      }
      a {
        color: inherit !important;
        text-decoration: none !important;
        width: 100%;
        display: block;
      }
    }

  }

  &__details-item-icon{
    display: flex;
    gap: 12px;
    align-items: flex-start;
  }
}


