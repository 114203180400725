@import "../../../mixins";

.close-icon {
  cursor: pointer;
  &.MuiSvgIcon-root {
    position: absolute;
    right: 20px;
    color: var(--gray-400);
  }
}
.custom-dialog {
  img {
    height: 180px;
    object-fit: contain;
    aspect-ratio: 1;
    margin-top: 30px;
  }
  .MuiDialog-paper {
    padding: 20px;
    @include media-breakpoint-up(md) {
      min-width: 726px;
      max-width: 800px;
    }
  }
  p {
    text-align: center;
    &:first-child {
      font-family: var(--font-family--bold);
      font-size: var(--font-size-base);
      line-height: 16px;
      @include media-breakpoint-up(md) {
        line-height: 26px;
      font-size: var(--font-size-24);
      }
      color: var(--gray-900);
    }
    &:nth-child(2) {
      font-family: var(--font-family--medium);
      font-size: var(--font-size-14);
      color: var(--gray-500);
    }
  }
}
