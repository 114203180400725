@import "../../../mixins";
.admin {
  .service-order {
    &__block {
        margin-top: 16px;
      background-color: var(--white);
      box-shadow: 0 12px 16px -4px #2a334208;
      border: 1px solid #e2e9f5;
      border-radius: 8px;
    }
    &__filter{
      margin-top: 16px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
      }

    }
  }

  .MuiTabPanel-root {
    border-top: 1px solid #e2e9f5;
  }
  .MuiBox-root {
    margin-bottom: 0;
  }
  .MuiTabs-root {
    padding: 0 24px;

    .MuiTabs-flexContainer {
      @include media-breakpoint-up(md) {
        gap: 30px;
      }
    }
    .css-cjctlb-MuiButtonBase-root-MuiTab-root {
      color: var(--gray-90);
      font-size: 12px;
      padding: 24px 6px;
      @include media-breakpoint-up(md) {
        font-size: var(--font-size-base);
        padding: 24px 12px;
      }
      opacity: 1;
      font-family: var(--font-family--medium);

      &.Mui-selected {
        background-color: rgba(0, 124, 222, 0.05);

        font-family: var(--font-family--bold);
      }
    }
    .PrivateTabIndicator-root-1.PrivateTabIndicator-colorSecondary-3.MuiTabs-indicator {
      background-color: #007CDE;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 4px;
    }
  }
}
