@import "../../../mixins";

.mt-40 {
    margin-top: 40px;
    margin-bottom: 20px;
}

.MuiTableCell-root.css-y8ay40-MuiTableCell-root,
.MuiTableCell-root.css-i02g0k-MuiTableCell-root {
    color: var(--dark-blue);
    font-size: var(--font-size-18);
    font-weight: bold;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium {
    font-size: var(--font-size-base);
    color: var(--gray-800);
}

.MuiTableContainer-root.css-41abqd-MuiTableContainer-root {
    @include media-breakpoint-up(lg) {
        max-height: 635px;
    }
}

.garage-list {
    .MuiPaper-elevation.MuiPaper-rounded {
        box-shadow: unset;
    }
.service-dashboard__header{
    h3{
        font-size: 24px;
        margin-bottom: 24px;
    }
}
    table {
        border-collapse: separate;
        border-spacing: 0 8px;
        // margin-top: -10px;
        background-color: var(--white);
        border-top: 1px solid #e2e9f5;
    }

    td:first-child {
        border-left: 1px solid #e2e9f5;
        border-radius: 10px 0 0 10px;
    }

    td:last-child {
        border-right: 1px solid #e2e9f5;
        border-radius: 0 10px 10px 0;
    }

    th {
        @extend .xs-text-light;
        font-family: var(--font-family--primary);
        color: var(--gray-500);
        border-bottom: 0 !important;
        padding: 14px 14px 0;
    }

    td {
        @extend .sm-text-dark;
        font-family: var(--font-family--medium);
        margin-bottom: 10px;
        padding: 14px 14px;
        vertical-align: middle;
        border: solid 1px #e2e9f5;
        border-style: solid none;
        font-weight: 500;
        // border-bottom: 1px solid #e2e9f5;
        // border-top: 1px solid #e2e9f5;

        button {
            padding: 0;
            border: 0;
            font-size: var(--font-size-14);
            cursor: pointer;

            &:hover {
                background-color: transparent;
                color: #2f80ed;
                border: 0;
            }
        }

        .downarrow {
            // background-image: url("../../../../../public/images/down-arrow.svg");
            width: 21px;
            height: 20px;
            background-color: transparent;

            &:hover {
                background-color: transparent;
            }
        }

        .map-link {
            display: flex;
            align-items: center;
            gap: 6px;
            text-decoration: none;
            color: #2F80ED;
            svg{
                width: 16px;
            }
        }

        &.table-action {
            position: relative;

            &::before {
                content: "";
                width: 1px;
                height: 23px;
                background-color: #e2e9f5;
                position: absolute;
                left: -5px;
                top: 13px;
            }
        }

        .edit-action {
            margin-right: 20px;

        }

        .delete-action {}
    }

    &__table {
        background-color: white;
        padding: 20px;
        border-radius: 8px;
        border: 1px solid #E2E9F5;
    }

    &__table-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        flex-wrap: wrap;
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
    }
    &__table-search{
        gap: 16px;
        width: 100%;
        @include media-breakpoint-up(md) {
            width: auto;
        }
    }
    &__action{
        @include media-breakpoint-down(md) {
        margin-top: 30px;
        margin-left: auto;
        }
    }
    &__table-search-input {
        border: 1px solid #D5DAE1;
        border-radius: 8px;
        padding: 10px 16px;
        width: 100%;
        @include media-breakpoint-up(md) {
        min-width: 300px;
        }
        background-image: url("../../../../public/images/search-icon.svg");
        background-repeat: no-repeat;
        background-position: 20px;
        background-size: 20px;
        padding-left: 50px;
        &:focus-visible{
            outline: #D5DAE1 auto 1px
        }
    }
}

.pagenination {
    ul {
        border: 1px solid #eef0f3;
        border-radius: 6px;
        display: inline-flex;

        li {
            border-right: 1px solid #eef0f3;
            background-color: white;

            &:first-child {
                padding: 0 10px;
                background-color: white;
                button{
                    text-transform: capitalize;
                }
               
            }

            &:last-child {
                padding: 0 10px;
                background-color: white;
                button{
                    text-transform: capitalize;
                }
               
            }

            button {
                outline: 0;
                border: 0;
                font-size: 14px;
                font-family: var(--font-family--medium);
                color: #556987;
                height: 36px;
                background-color: white;
                padding: 0;
                min-width: 36px;
            }
        }
    }
}
.go-back{
    a{
    text-decoration: none;
    color: #2A3342;
    }
}