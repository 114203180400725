.form__group_job {
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
  flex: 0 1 50%;
  .jobsheet__text {
    font-weight: var(--font-family--semi-bold);
    &.capital {
      text-transform: uppercase;
    }
  }
  .form__label {
    flex: 0 1 30%;
  }
  > div {
    flex: 0 1 70%;
    input {
      width: 100%;
    }
  }
}

.container_row {
  display: flex;
  align-items: center;
  //   justify-content: space-around;
  flex: 0 1 50%;
}

.horizontal {
  color: var(--dark-blue);
  border-width: 2px;
}
.action-container {
  display: flex;
  gap: 10px;
}

.button {
  &.table {
    height: 20px;
    width: 60px;
    padding: 2px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
}

.button-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.invoice-page {
  .form__input {
    width: 100%;
  }
  .table-responsive-sm {
    width: 100%;
    margin-top: 30px;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    td,
    th {
      border: 1px solid #ececec;
      text-align: center;
      font-weight: bold;
    }
    td {
      font-weight: normal;
    }
  }
  .action-container {
    justify-content: center;
  }
  &.button-container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    margin-bottom: 30px;
    margin-right: 30px;
    margin-top: 30px;
  }
}
