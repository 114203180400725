@import "../../../mixins";

.textimonails{
    .container{
        max-width: 1034px;
        margin: 0 auto;
        margin-top: 40px;
        margin-bottom: 30px;
        @include media-breakpoint-up(md){
            margin-top: 110px;
    margin-bottom: 100px;
        }
    }
    .slider{
&__item{
text-align: center;

padding-top: 40px;
}
&__item-title{
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 35px;
    @include media-breakpoint-up(md){
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 64px;
        }
    @include media-breakpoint-up(lg){
    font-size: 36px;
    line-height: 45px;
    }
    font-family: var(--font-family--semi-bold);
   
    letter-spacing: -2%;
   
    position: relative;
    z-index: 9999;
    @include media-breakpoint-up(sm){
        font-size: var(--font-size-h1);
        line-height: 60px;
    }
    
&::before{
    content: "";
    position: absolute;
    
    background-image: url("../../../../public/images/leftoQuote.svg");
    width: 142px;
    height: 98px;
    top: -30px;
    left: 0px;
    z-index: -1;

}
&::after{
    content: "";
    z-index: -1;
    position: absolute;
    bottom:-30px;
    background-image: url("../../../../public/images/rightQuote.svg");
    width: 142px;
    height: 98px;
    right: 0;

}

}
&__item-image{
    text-align: center;
}

&__item-author{
    margin-top: 0px;
    font-size: 20px;
    @include media-breakpoint-up(md){
    margin-top: 24px;
    font-size: 24px;
    }
    font-family: var(--font-family--semi-bold);
    color: var(--gray-800);
}
&__item-designation{
    margin-top: 0px;
    font-size: 16px;
    @include media-breakpoint-up(md){
   margin-top: 8px;
   font-size: 18px;
    }
    color: var(--gray-500);
}
    }
    .slick-dots {
bottom: 0px;
    }
   
}
.slick-dots {
    
    li{
    margin: 0;
    &.slick-active button:before {
    color: #2F80ED;
    opacity: 1;
    }
    button{
        &::before {
            font-size: 12px;
            // color: #EEF0F3;
        }
    }
    
}
}