@import "../../mixins";

.contact {
  padding: 0 0px 45px 0;

  .align-center {
    text-align: center;
    color: var(--green-1);
  }
  .office-svg {
    width: 71px;
    @include media-breakpoint-up(md) {
    width: 100px;
    }
  }
  .container {
    max-width: 1100px;
  }
  &__top {
    background-color: #f7f8f9;
    height: 287px;
    padding-top: 45px;
  }
  &__bottom {
    box-shadow: 0px 32px 64px 0px rgba(85, 105, 135, 0.4);
    border-radius: 16px;
   
    top: -53px;
    position: relative;
    background-color: white;
    padding: 0 16px 34px;
    @include media-breakpoint-up(md) {
      padding: 0 16px 16px;
      }
    @include media-breakpoint-up(lg) {
      padding: 0 64px 65px;
    }
    &.admin {
      top: 0 !important;
    }
  }
  &__badge {
    border-radius: 36px;
    color: #22c55e;
    background-color: #dcfce7;
    font-size: 12px;
    font-family: var(--font-family--medium);
    padding: 2px 8px;
    display: inline-block;
    margin-bottom: 16px;
  }

  &__block {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 103px;
    }
  }

  &__left {
    @include media-breakpoint-up(lg) {
      flex: 0 1 54%;
    }
  }

  &__right {
    display: flex;
    gap: 16px;

    @include media-breakpoint-up(md) {
      // flex: 0 1 45%;
    }
    .button--primary {
      &:hover {
        border: 1px solid #d5dae1;
      }
    }
  }

  .button--outline {
    border: 1px solid #d5dae1;
    color: #333f51;

    &:hover {
      color: white;
    }
  }

  &__details {
    display: flex;
    margin-top: 70px;
    flex-direction: column;
    @include media-breakpoint-up(lg) {
      flex: 0 1 50%;
    }
    gap: 24px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    // flex-direction: column;
    display: flex;
    gap: 24px;
    align-items: center;
    @include media-breakpoint-up(md) {
      flex: 0 1 47%;
      align-items: left;
    }

    .dark-text {
      font-family: var(--font-family--bold);
      font-weight: 700;
      color: #2a3342;
      font-size: 18px;
      @include media-breakpoint-up(md) {
        font-size: 30px;
      }
    }

    .light-text {
      color: #556987;
      font-size: 14px;
      @include media-breakpoint-up(md) {
        font-size: 20px;
      }
      text-decoration: none;
    }
  }
  &__form {
    padding: 40px;
    width: 100%;
    margin-top: 70px;
    @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 16px;
    order: -1;
}
    @include media-breakpoint-up(lg) {
      flex: 0 1 58%;
      width: 0;
      margin-top: 70px;
    }
    background-color: #f7f8f9;
    border-radius: 6px;
    button {
      width: 100%;
    }
    textarea {
      @include placeholder {
        font-size: var(--font-size-base);
        color: var(--gray-500);
      }
      padding: 6px 10px;
      @include media-breakpoint-up(md) {
        padding: 6px 8px;
        height: 150px !important;
      }
      @include media-breakpoint-up(lg) {
        padding: 6px 10px;
      }
      border-radius: 8px;

      //box-shadow: 6px 1px rgba(0, 0, 0, 0.05);
      font-size: var(--font-size-base);
    }
  }
  // &__

  .form__input {
    @include placeholder {
      font-size: var(--font-size-base);
      color: var(--gray-500);
    }
    height: 46px;
    border: 1px solid var(--gray-200);
    padding: 6px 10px;
    @include media-breakpoint-up(md) {
      padding: 0px 8px;
    }
    @include media-breakpoint-up(lg) {
      padding: 6px 10px;
    }
    border-radius: 8px;

    //box-shadow: 6px 1px rgba(0, 0, 0, 0.05);
    font-size: var(--font-size-base);

    &:focus-visible {
      outline: none;
    }

    &:focus {
      border-color: var(--blue-1);
    }

    &--textarea {
      padding: 16px;
    }

    &--checkbox {
      height: auto;
    }
  }
  .form__input-error {
    // display: none;
  }
  &__item-flex {
    display: flex;
    flex-direction: column;
  }
  .social-links {
    display: flex;
    gap: 26px;
  }
}
