/**
Title.scss settings
========================================================================== */
/**/
@import "./mixins";

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family--primary);

  color: var(--gray-050);

  color: var(--gray-800);
  font-size: var(--font-size-14);
  @include media-breakpoint-up(md) {
    font-size: var(--font-size-base);
  }
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
* {
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
    height: 12px;
  }

  /* background of the scrollbar except button or resizer */
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  /* scrollbar itself */
  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
  }

  /* set button(top and bottom of the scrollbar) */
  &::-webkit-scrollbar-button {
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family--primary);
  line-height: 1;
  margin: 0 0 8px;
}

h1 {
  font-family: var(--font-family--bold);
  font-size: var(--font-size-24);
  line-height: 36px;
  letter-spacing: -2%;

  @include media-breakpoint-up(md) {
    font-size: var(--font-size-h1);
    line-height: 72px;
  }
}

h2 {
  font-family: var(--font-family--bold);
  font-size: var(--font-size-24);
  line-height: 32px;

  @include media-breakpoint-up(md) {
    font-size: var(--font-size-30);
    line-height: 38px;
  }
}

h3 {
  font-family: var(--font-family--bold);
  font-size: var(--font-size-base);
  line-height: 32px;

  @include media-breakpoint-up(lg) {
    font-size: var(--font-size-24);
    line-height: 20px;
  }
}

.button {
  // @include transition($transition-time--base all ease-in-out);
  position: relative;
  display: inline-block;
  padding: 10px 24px;

  @include media-breakpoint-up(md) {
    padding: 10px 24px;
  }

  cursor: pointer;
  text-align: center;
  text-decoration: none;
  letter-spacing: 0;
  line-height: 24px;
  //  text-transform: capitalize;
  color: var(--white);
  border: none;
  border-radius: 2px;
  background-color: var(--blue-1);
  @include media-breakpoint-up(md) {
    font-size: var(--font-size-base);
  }
  font-family: var(--font-family--medium);

  &:hover {
    //   background-color: $color--teal;
    color: var(--blue-2);
    background-color: var(--white);
  }

  &--outline {
    color: var(--blue-2);
    background-color: var(--white);
    border: 1px solid var(--blue-2);

    &:hover {
      color: var(--white);
      background-color: var(--blue-1);
    }
  }

  &--green {
    color: var(--white);
    background-color: #22c55e;
    border: 1px solid #22c55e;

    &:hover {
      color: #22c55e;
      background-color: var(--white);
    }
  }

  &--light {
    background-color: var(--blue-150);
    border: 1px solid var(--blue-2);
    color: #3b82f6;
  }

  &--disabled {
    color: var(--gray-400);
    border: 1px solid #8896ab;
    pointer-events: none;
    background-color: var(--white);
  }
}

img {
  max-width: 100%;
  height: auto;
}

hr {
  color: var(--gray-100);
}

main {
  margin-top: 30px;
}

.MuiSelect-select {
  &.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
    border-radius: 8px !important;
    padding: 11.5px 14px;
    font-family: var(--font-family--primary);
    font-size: var(--font-size-14);

    @include media-breakpoint-up(md) {
      font-size: var(--font-size-base);
    }
  }
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  font-family: var(--font-family--primary) !important;
}
.MuiOutlinedInput-notchedOutline {
  border: 1px solid #d5dae1 !important;
}

.MuiButtonBase-root {
  &.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    padding-left: 8px;
    padding-right: 8px;
    margin: 0 16px;
    line-height: 20px;
    border-radius: 4px;
    color: var(--black);
    font-size: var(--font-size-14);
    @include media-breakpoint-up(md) {
      font-size: var(--font-size-base);
    }
    font-family: var(--font-family--primary);
    min-height: 30px;

    &.Mui-selected {
      background-color: #007cde;
      color: var(--white);

      &:hover {
        background-color: #007cde;
      }
    }
  }
}

.MuiInputBase-inputAdornedEnd.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 12px;
}

.MuiInputBase-adornedEnd.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  background-color: #d5dae1;
}

.MuiListSubheader-root.MuiListSubheader-gutters.MuiListSubheader-sticky {
  line-height: 44px;
}

.MuiBox-root {
  margin-bottom: 16px;
}

.intl-tel-input {
  .form-control {
    width: 100%;
    height: 46px;
    border-radius: 4px;
    border: 1px solid var(--gray-200);

    font-size: var(--font-size-14);
    @include media-breakpoint-up(md) {
      font-size: var(--font-size-base);
    }
    font-family: var(--font-family--primary);
    @include placeholder {
      color: #8896ab;
    }
  }

  .country-list {
    width: 100%;

    .country-name {
      color: var(--black);
    }
  }
}

.MuiButton-containedPrimary.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background-color: var(--blue-1);
  height: 46px;
  display: flex;
  align-items: center;
  font-size: var(--font-size-14);
  @include media-breakpoint-up(md) {
    font-size: var(--font-size-base);
  }
  font-family: var(--font-family--medium);
  line-height: 24px;
  color: var(--gray-050);
  text-transform: capitalize;
}

.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label {
  font-size: var(--font-size-14);
  @include media-breakpoint-up(md) {
    font-size: var(--font-size-base);
  }
}

.MuiInputBase-input.MuiOutlinedInput-input.Mui-readOnly.MuiInputBase-readOnly {
  font-size: var(--font-size-14);
  @include media-breakpoint-up(md) {
    font-size: var(--font-size-base);
  }
}

.PrivateTabIndicator-root-12.PrivateTabIndicator-colorSecondary-14.MuiTabs-indicator {
  background-color: #007cde;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 4px;
}
