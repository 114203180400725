@import "../../../mixins";

.service-gurantee {
    padding: 64px 0 35px;
    @include media-breakpoint-up(md) {
    padding: 64px 0;
    }
    background-color: #F7F8F9;

    &__title {
        color: var(--gray-900);
        font-size: 20px;
        line-height: 26px;
    
        @include media-breakpoint-up(md) {
            line-height: 36px;
    
        font-size: 24px;
            }
       
        @include media-breakpoint-up(lg) {
            font-size: 48px;
            line-height: 44px;
            }
        letter-spacing: -2%;
        font-family: var(--font-family--bold);
        margin-bottom: 16px;
        text-align: center;
    }

    &__text {
        margin-bottom: 44px;
        text-align: center;
        font-size: var(--font-size-14);
        @include media-breakpoint-up(lg) {
        font-size: var(--font-size-20);
        line-height: 30px;
        }
        color: var(--gray-500);
       
        font-family: var(--font-family--medium);

    }

    &__block {
        @include media-breakpoint-up(sm) {
            display: flex;
            gap:16px
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 30px;
        @include media-breakpoint-up(md) {
        margin-bottom: 50px;
        }

        @include media-breakpoint-up(sm) {
            margin-bottom: 81px;
        }
    }

    &__item-title {
        font-family: var(--font-family--bold);
       
        text-align: center;
        color: var(--gray-900);
        
        font-size: var(--font-size-18);
        line-height: 22px;
        margin-bottom: 4px;
        margin-top: 18px;
        @include media-breakpoint-up(md) {
            margin-bottom: 14px;
            margin-top: 28px;
            line-height: 32px;
        font-size: var(--font-size-24);
        }
    }

    &__item-text {
        font-size: var(--font-size-base);
        color: var(--gray-500);
        font-family: var(--font-family--medium);
        @include media-breakpoint-up(xl) {
        padding: 0 54px;
        }

    }
}