@import "../../../../mixins";

.filter-component {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  gap: 12px 24px;
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    gap: 24px;
  }

  &__search {
    gap: 16px;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
    input::-webkit-input-placeholder {
      font-size: 10px;
    }
  }

  &__search-input {
    border: 1px solid #d5dae1;
    border-radius: 8px;
    padding: 10px 16px;
    min-width: 300px;
    background-image: url("../../../../../public/images/search-icon.svg");
    background-repeat: no-repeat;
    background-position: 20px;
    background-size: 20px;
    padding-left: 50px;
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }

    &:focus-visible {
      outline: #d5dae1 auto 1px;
    }
  }

  &__date {
    display: flex;

    .react-datepicker-wrapper {
      &:nth-child(1) {
        input {
          border-right: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
      }

      &:nth-child(2) {
        input {
          border-left: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }

      input {
        width: 105px;
        border: 1px solid #d5dae1;
        border-radius: 8px;
        padding: 10px 10px;
        font-size: 16px;
      }
    }
  }
}
